<template>
  <div
    class="user-block"
    :class="{ 'is-pending': !user }"
  >
    <div class="user-block__content">
      <div
        class="user-block__photo"
        @click="showUserMenu"
      >
        <div class="user-block__photo-initials">
          <span>{{ initials }}</span>
        </div>
      </div>

      <div class="user-block__info">
        <div
          class="user-block__name"
          @click="showUserMenu"
        >
          {{ name }}
        </div>
        <div class="user-block__roles">
          <multiselect
            v-if="roles"
            :options="roles"
            :placeholder="roleName"
            :searchable="false"
            @select="changeRole"
          />
          <div
            v-else
            class="user-block__role"
          >
            {{ roleName }}
          </div>
        </div>
        <transition
          name="fade-out-in"
          mode="out-in"
        >
          <div
            v-if="isMenuVisible"
            class="user-block__menu"
          >
            <a
              class="user-block__profile user-block__link"
              :href="`${config.APP_V2_URL}/profile`"
            >
              Профиль
            </a>
            <template v-if="isAdmin">
              <a
                class="user-block__link"
                href="/admin/admin_users_list"
              >
                Список пользователей
              </a>
              <a
                class="user-block__link"
                href="/admin/admin_user_create"
              >
                Создать пользователя
              </a>
            </template>

            <TheLocaleChanger />

            <div
              class="logout"
              @click="logout"
            >
              Выйти
              <base-icon
                class="logout__icon"
                height="10px"
                icon-name="Arrow"
                viewBox="0 0 20 20"
                width="12px"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Multiselect from 'vue-multiselect';
import { roleNames, userRoles, navsMapping } from '~utils/globals';
import TheLocaleChanger from '~components/TheLocaleChanger';
import config from '../../../config';

export default {
  name: 'TheHeaderMenu',
  components: {
    Multiselect,
    TheLocaleChanger,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { dispatch, state, getters } = useStore();
    const router = useRouter();

    // Данные для отрисовки мени пользователя и инициалов на фото.
    const name = computed(() => {
      const fName = state.user.name ? `${state.user.name[0]}.` : '';
      const sName = state.user.surname;
      const patr = state.user.patronymic ? `${state.user.patronymic[0]}.` : '';
      if (!sName) {
        return state.user.login;
      }
      if (sName && fName && !patr) {
        return `${sName} ${fName[0]}`;
      }
      return `${sName} ${fName} ${patr}`;
    });
    const initials = computed(() => {
      const fName = state.user.name;
      const sName = state.user.surname;
      const { login } = state.user;
      if (!fName || !sName) {
        return login ? `${login[0]}${login[0]}`.toUpperCase() : '';
      }
      return `${sName[0]}${fName[0]}`;
    });

    const role = computed(() => getters['user/role']);
    const roleName = computed(() => roleNames[role.value]);
    const roles = computed(() => (state.user.roles ? state.user.roles.map(item => roleNames[item]) : null));
    async function changeRole(value) {
      const [newRole] = Object.entries(roleNames).find(
        ([, roleValue]) => roleValue === value,
      );
      await dispatch('user/CHANGE_ROLE', newRole);
      const isNotOpenProfileViewPage = router.currentRoute.value.fullPath !== '/profile/view';
      if (isNotOpenProfileViewPage) {
        const rolePageRoutes = Object.values(navsMapping[newRole]);
        await router.push(rolePageRoutes[0]);
      }
    }
    async function logout() {
      const { data } = await axios.get('/api/logout');
      if (data.result === 'OK') {
        window.location.reload();
      }
    }

    const menuHidden = ref(true);
    function showUserMenu() {
      menuHidden.value = false;
      document.addEventListener('click', this.closeUserMenu, true);
    }
    function closeUserMenu(ev) {
      if (ev.target.closest('.user-block__menu')) {
        return;
      }

      if (!menuHidden.value) {
        menuHidden.value = true;
        document.removeEventListener('click', this.closeUserMenu, true);
      }
    }

    const isMenuVisible = computed(() => !menuHidden.value);
    const isAdmin = computed(() => role.value === userRoles.admin);

    return {
      router,
      menuHidden,
      changeRole,
      logout,
      roleName,
      roles,
      showUserMenu,
      closeUserMenu,
      name,
      initials,
      isMenuVisible,
      isAdmin,
      config,
    };
  },
};
</script>

<style lang="scss" scoped>

.user-block {
  font-size: 16px;
  &__photo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 7px;
    cursor: pointer;

    &-initials {
      display: grid;
      place-items: center;
      font-size: 18px;
      line-height: 23px;
      height: 100%;
      text-align: center;
      background: $pisa-purple;
      span {
        color: $grey;
      }
    }
  }

  &__link {
    display: block;
    word-break: keep-all;
    line-height: 1;
    margin-bottom: 8px;

    &:hover {
      color: $pisa-purple;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__name {
    cursor: pointer;
    font-size: 16px;
    line-height: 21px;
  }
  &__role {
    font-size: 12px;
    line-height: 21px;
  }
  &__menu {
    position: absolute;
    padding: 5px 16px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 24%);
    border: 1px solid $grey;
    border-radius: 8px;
    background: #fff;
    color: $black;
    top: 110%;
    left: 0;
    z-index: 1000;
    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__icon {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }
}
#pisa {
  .user-block {
    .logout {
      &__icon {
        fill: black;
      }
      &:hover .base-svg {
        fill: $pisa-purple;
      }
    }

    &__profile,
    .logout {
      &:hover {
        color: $pisa-purple;
      }
    }
  }
}

#erg {
  .user-block {
    &__photo-initials {
      background: $erg-blue;
      span {
        color: $erg-green;
      }
    }
    .logout {
      &__icon {
        fill: black;
      }

      &:hover .base-svg {
        fill: $erg-blue;
      }
    }

    &__profile,
    .logout {
      &:hover {
        color: $erg-blue;
      }
    }
    :deep .multiselect__select {
      filter: invert(100%);
    }
  }
}

#almaty {
  .user-block {
    &__photo-initials {
      background: $almaty-green;
      span {
        color: $almaty-blue;
      }
    }
    .logout {
      &__icon {
        fill: black;
      }
      &:hover .base-svg {
        fill: $almaty-green;
      }
    }
    &__profile,
    .logout {
      &:hover {
        color: $almaty-green;
      }
    }
    :deep .multiselect__select {
      filter: invert(100%);
    }
  }
}
#sultan {
  .user-block {
    &__photo-initials {
      background: $sultan-blue;
      span {
        color: $sultan-grey;
      }
    }
    .logout {
      &__icon {
        fill: black;
      }

      &:hover .base-svg {
        fill: $sultan-blue;
      }
    }

    &__profile,
    .logout {
      &:hover {
        color: $sultan-blue;
      }
    }
  }
}
:deep .multiselect {
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  &--active {
    .multiselect__select {
      transform: rotate(180deg);
    }
  }

  &__content-wrapper {
    position: absolute;
    overflow: hidden;
    top: 110%;
    z-index: 1000;
    left: 0;
    box-shadow: 0 1px 5px rgb(0 0 0 / 24%);
    border: 1px solid $grey;
    background: #fff;
    border-radius: 8px;
    font-size: 16px;
  }
  &__select {
    margin-left: 10px;
    width: 7px;
    height: 7px;
    background: url('~@/assets/icons/botArrow.svg') no-repeat center;
  }
  &__content {
    list-style: none;
    color: $black;
    width: 100%;
  }

  &__option {
    &:hover {
      background: $grey;
    }
    display: block;
    padding: 7px 15px;
  }
}
</style>
