import Articles from '~utils/services/Articles';

const getInitialState = () => ({
  school: null,
  primarySchool: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    school: state => state.school ?? [],
    primarySchool: state => state.primarySchool ?? [],
    isNotLoaded: state => state.school === null || state.primarySchool === null,
    getSchoolArticleById: state => id => Articles.getArticleById(id, state.school),
    getSchoolArticles: state => ids => Articles.getArticlesByIds(ids, state.school),
  },
  mutations: {
    set: (state, { school, primarySchool }) => {
      state.school = school;
      state.primarySchool = primarySchool;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    fetch: async ({ commit }) => {
      const articles = await Articles.fetch();
      commit('set', articles);
    },
  },
};
