<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5058 7.56458L12.4724 7.21458L10.8974 3.50625C10.6141 2.83125 9.64747 2.83125 9.36414 3.50625L7.78914 7.22292L3.76414 7.56458C3.0308 7.62292 2.7308 8.53958 3.28914 9.02292L6.34747 11.6729L5.4308 15.6062C5.26414 16.3229 6.03914 16.8896 6.67247 16.5063L10.1308 14.4229L13.5891 16.5145C14.2224 16.8979 14.9974 16.3312 14.8308 15.6145L13.9141 11.6729L16.9724 9.02292C17.5308 8.53958 17.2391 7.62292 16.5058 7.56458Z"
      fill="#FFC120"
    />
  </svg>
</template>
