<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
  >
    <path
      d="M22.7447 0.718763C22.4749 0.495872 22.1041 0.437216 21.7757 0.570952L0.90576 9.06436C0.901067 9.0667 0.896375 9.06905 0.891682 9.0714C0.314515 9.33183 -0.0327378 9.90195 0.00244221 10.526V10.5307C0.0423282 11.1595 0.455278 11.6804 1.05823 11.8611L8.95786 14.2495L9.24411 19.599V19.6014C9.26757 19.9862 9.51861 20.3146 9.88464 20.4437C9.98318 20.4812 10.0888 20.5 10.2014 20.5C10.4782 20.5 10.7316 20.3874 10.917 20.1809L13.5682 17.2458L17.3715 19.8782C17.6225 20.0542 17.9134 20.1457 18.2184 20.1457C18.9153 20.1457 19.53 19.646 19.6755 18.9585C19.6755 18.9562 19.6755 18.9538 19.6778 18.9515L23.0752 1.64332C23.1409 1.30077 23.0118 0.939461 22.7443 0.718899L22.7447 0.718763ZM20.5603 4.52198L17.9255 17.9376L11.3773 13.4045L20.5603 4.52198ZM11.9824 16.1451L11.0252 17.2033L10.9313 15.4178L11.9824 16.1451ZM18.2912 4.05263L9.61505 12.4474L2.75923 10.3756L18.2912 4.05263Z"
    />
  </svg>
</template>
