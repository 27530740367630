import _ from 'lodash';
import api from '~utils/api';
import { apiEndpoints } from '~utils/globals';
import User from '../entities/User';
import config from '../../../../config';

export default class Users {
  static async fetch(roles = [], additionalOptions, sort = null) {
    if (sort) {
      additionalOptions.sort = JSON.stringify(sort);
    }
    const finalOptions = new URLSearchParams({
      roles,
      ..._.omitBy(additionalOptions, val => val === undefined || val === null),
    });
    const response = await api.get(`${apiEndpoints.user}/getAll/?${finalOptions}`);
    return response.data;
  }

  static async fetchWithoutPagination({ role, cityId }) {
    const params = new URLSearchParams({
      role,
      cityId,
    });
    const response = await api.get(`${apiEndpoints.usersWithoutPagination}/?${params}`);
    return response.data.map(user => new User(user));
  }

  static async fetchByRegion({
    roles = [], regionId, page, isArchived, schoolId, fioContains = '',
  }) {
    const { users, pagination } = await Users.fetch(roles, {
      regions: [regionId],
      schoolId,
      page,
      isArchived,
      fioContains,
    });
    return { users, pagination };
  }

  static async remove(id) {
    await api.delete(`${apiEndpoints.user}/${id}`);
  }

  static async create({ data, role }) {
    const res = await api.post(apiEndpoints.user, {
      role,
      ...data,
    });
    return { id: res.data.id, result: res.data.result };
  }

  static async update(user) {
    const res = await api.put(apiEndpoints.user, {
      editOtherUser: user._id,
      ...user,
    });
    return res.data;
  }

  static async updatePassword({ id, newPassword }) {
    await api.patch(`${apiEndpoints.user}/reset/password`, {
      id,
      password: newPassword,
    });
  }

  static async fetchAuthorizedUser() {
    const response = await api.get(apiEndpoints.user);
    return new User(response.data.user);
  }

  static async syncAuthorizedUser() {
    await api.patch('/updateuseros', { domainOS: config[window.location.origin].APP_URL_OS });
  }

  static async sendResetPasswordMail(login) {
    return api.post(`${apiEndpoints.sendResetPasswordMail}`, { login });
  }

  static async validateResetPassToken(token) {
    try {
      const { data } = await api.get(`${apiEndpoints.verifyResetPassToken}/${token}`);
      return { isValid: true, message: data.message, login: data.login };
    } catch (e) {
      return { isValid: false, message: e.response.data.message };
    }
  }

  static async changePassword(token, password) {
    const { data } = await api.post(`${apiEndpoints.changeUserPassword}/${token}`, { password });
    const { login, password: newPassword } = data;
    return { login, newPassword };
  }

  static getIds(users) {
    return users.map(user => user._id);
  }

  static getUsersById(ids, users) {
    if (users === null || users === undefined) {
      return [];
    }

    return users.filter(user => ids.includes(user._id));
  }

  static removeById(id, users) {
    if (users === null || users === undefined) {
      return [];
    }

    return users.filter(user => user._id !== id);
  }
}
