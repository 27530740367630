<template>
  <component
    :is="iconName"
    class="base-svg"
    :title="iconName"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  />
</template>

<script>
/* eslint-disable vue/require-default-prop */

import AngleDown from '../Icons/AngleDown';
import Arrow from '../Icons/Arrow';
import ExToggleArrow from '../Icons/ExToggleArrow';
import ExQuestionIcon from '../Icons/ExQuestionIcon';
import XIcon from '../Icons/XIcon';
import Loupe from '../Icons/Loupe';
import LogoutIcon from '../Icons/LogoutIcon';
import CheckedCheckbox from '../Icons/CheckedCheckbox';
import CheckedRadio from '../Icons/CheckedRadio';
import PeopleIcon from '../Icons/PeopleIcon';
import WorkGraphic from '../Icons/WorkGraphic';
import Edit from '../Icons/Edit';
import Delete from '../Icons/Delete';
import DownloadIcon from '../Icons/DownloadIcon';
import Help from '../Icons/Help';
import ValidatedFields from '../Icons/ValidatedFields';
import Pencil from '../Icons/Pencil';
import Trash from '../Icons/Trash';
import PaperPlane from '../Icons/PaperPlane';
import ExportIcon from '../Icons/ExportIcon';
import EditGrade from '../Icons/EditGrade';
import Star from '../Icons/Star';
import StarActive from '../Icons/StarActive';
import Printer from '../Icons/Printer';
import ArrowCircle from '../Icons/ArrowCircle';
import ResponderMedal from '../Icons/ResponderMedal';
import IconCheck from '@/components/Icons/IconCheck.vue';
import Plus from '../Icons/Plus';
import IconExclamationMark from '@/components/Icons/IconExclamationMark.vue';

export default {
  name: 'BaseIcon',
  components: {
    AngleDown,
    Arrow,
    ExToggleArrow,
    ExQuestionIcon,
    XIcon,
    Loupe,
    LogoutIcon,
    CheckedCheckbox,
    CheckedRadio,
    PeopleIcon,
    WorkGraphic,
    Edit,
    Delete,
    DownloadIcon,
    Help,
    ValidatedFields,
    Pencil,
    Trash,
    PaperPlane,
    ExportIcon,
    EditGrade,
    Star,
    Printer,
    ArrowCircle,
    ResponderMedal,
    StarActive,
    Plus,
    IconCheck,
    IconExclamationMark
  },
  // eslint-disable-next-line
  props: ['width', 'height', 'iconName', 'viewBox'],
};
</script>

<style lang="scss" scoped>
#pisa {
  .base-svg {
    fill: $pisa-purple;
    svg {
      fill: $pisa-purple;
    }
  }
}

#erg {
  .base-svg {
    fill: $erg-blue;
    svg {
      fill: $erg-blue;
    }
  }
}

#almaty {
  .base-svg {
    fill: $almaty-green;
    svg {
      fill: $almaty-green;
    }
  }
}

#sultan {
  .base-svg {
    fill: $sultan-blue;
    svg {
      fill: $sultan-blue;
    }
  }
}
</style>
