<template>
  <svg
    width="18px"
    height="30px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4 19C7.4 18.4933 7.42667 18.0267 7.48 17.6C7.56 17.1467 7.70667 16.7067 7.92 16.28C8.16 15.8267 8.48 15.36 8.88 14.88C9.30667 14.4 9.86667 13.8667 10.56 13.28C11.68 12.32 12.5333 11.4533 13.12 10.68C13.7067 9.90667 14 9.08 14 8.2C14 6.92 13.5733 5.86667 12.72 5.04C11.8667 4.21333 10.6933 3.8 9.2 3.8C7.70667 3.8 6.53333 4.21333 5.68 5.04C4.82667 5.86667 4.4 6.92 4.4 8.2H0.8C0.8 7.10666 0.986667 6.10666 1.36 5.2C1.76 4.26666 2.32 3.46666 3.04 2.8C3.78667 2.10666 4.68 1.57333 5.72 1.2C6.76 0.799998 7.92 0.599998 9.2 0.599998C10.48 0.599998 11.64 0.799998 12.68 1.2C13.72 1.57333 14.6 2.10666 15.32 2.8C16.0667 3.46666 16.6267 4.26666 17 5.2C17.4 6.10666 17.6 7.10666 17.6 8.2C17.6 8.94667 17.48 9.65333 17.24 10.32C17 10.9867 16.6667 11.6267 16.24 12.24C15.84 12.8267 15.3733 13.4 14.84 13.96C14.3067 14.4933 13.76 15 13.2 15.48C12.7467 15.8533 12.3733 16.2133 12.08 16.56C11.7867 16.88 11.5467 17.2 11.36 17.52C11.2 17.8133 11.08 18.12 11 18.44C10.9467 18.7333 10.92 19.0533 10.92 19.4V20.2H7.4V19ZM9.2 29.4C8.42667 29.4 7.76 29.1333 7.2 28.6C6.66667 28.04 6.4 27.3733 6.4 26.6C6.4 25.8 6.66667 25.1333 7.2 24.6C7.76 24.0667 8.42667 23.8 9.2 23.8C9.97333 23.8 10.6267 24.0667 11.16 24.6C11.72 25.1333 12 25.8 12 26.6C12 27.3733 11.72 28.04 11.16 28.6C10.6267 29.1333 9.97333 29.4 9.2 29.4Z"
    />
  </svg>
</template>
