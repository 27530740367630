<template>
  <div
    class="base-table"
    :class="{ 'base-table--is-loading': isLoading }"
  >
    <transition
      name="fade-out-in"
      mode="out-in"
    >
      <BaseSpinner
        v-if="isLoading"
        class="base-table__spinner"
      />
    </transition>
    <table>
      <thead>
        <tr class="base-table__tr">
          <th
            v-for="th in header"
            :key="th.field"
            class="base-table__th"
            :class="getClassNames(th)"
          >
            <div class="base-table__elem">
              <slot
                :title="th.title"
                :name="`header-cell-${th.field}`"
              >
                {{ th.title }}
              </slot>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isNotEmpty">
          <tr
            v-for="(tr, trIdx) in bodyTable"
            :key="trIdx"
            class="base-table__tr"
          >
            <td
              v-for="(td, keyName, tdIdx) in tr"
              :key="keyName"
              class="base-table__td"
              :class="getClassNames(header[tdIdx])"
            >
              <div
                class="base-table__elem"
                :class="header[tdIdx].type"
              >
                <div class="base-table__elem-content">
                  <slot
                    :bodyCell="{
                      value: td,
                      row: body[trIdx],
                      tableInfo,
                      indexTr: trIdx,
                      fieldName: keyName,
                    }"
                    :name="`body-cell-${keyName}`"
                  >
                    {{ td }}
                  </slot>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr
          v-else
          class="base-table__tr"
        >
          <td
            class="base-table__td base-table__td--text-center"
            :colspan="columnsCount"
          >
            {{ emptyMessage }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'BaseTable',
  props: {
    header: {
      type: Array,
      default() {
        return [
          {
            classNames: [''],
            title: 'Название модуля',
            field: 'name',
            type: 'text',
          },
        ];
      },
    },
    body: {
      type: Array,
      default() {
        return [
          {
            name: 'Имя',
          },
          {
            name: 'Имя',
          },
        ];
      },
    },
    emptyMessage: {
      type: String,
      required: false,
      default: 'Ничего нет',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const bodyTable = computed(() => {
      const { body } = props;
      const { header } = props;
      if (body) {
        return body.map(item => {
          const fields = {};
          header.forEach(info => {
            fields[info.field] = item[info.field];
          });
          return fields;
        });
      }

      return null;
    });
    const tableInfo = computed(() => {
      const { header, body } = props;
      return {
        header,
        body,
        bodyFilter: bodyTable,
      };
    });

    function getClassNames(headerEl) {
      let classNames = [];

      if (headerEl.classNames) {
        classNames = headerEl.classNames;
      }
      classNames.push(`base-table__elem--${headerEl.field}`);
      return classNames;
    }

    const isNotEmpty = computed(
      () => bodyTable.value && bodyTable.value.length > 0,
    );
    const columnsCount = computed(() => props.header.length);

    return {
      tableInfo,
      bodyTable,
      getClassNames,
      isNotEmpty,
      columnsCount,
    };
  },
};
</script>

<style lang="scss" scoped>

.base-table {
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    content: '';

    background-color: transparent;

    transition: background-color 0.25s;

    pointer-events: none;
  }

  &--is-loading {
    &::after {
      background-color: rgba(white, 0.45);

      pointer-events: auto;
    }
  }

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 6;

    content: '';

    transform: translate(-50%, -50%);
  }

  table {
    border-spacing: 0;
    font-size: 16px;
    padding: 0 10px 20px 10px;
    width: 100%;
  }

  thead th {
    background: #efeef1;
    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
    }

    &:after {
      background: #ffffff;
    }
  }

  thead th,
  tbody td {
    position: relative;

    &:after {
      content: '';
      display: block;
      height: 80%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }

  thead th:first-child,
  tbody td:first-child {
    padding-left: 20px;
  }

  thead th:last-child {
    &:after {
      display: none;
    }
  }

  tbody tr td {
    border-bottom: 1px solid #efeef1;
    border-left: none;
    border-right: none;

    &:first-child {
      border-left: 1px solid #efeef1;
    }

    &:last-child {
      border-right: 1px solid #efeef1;
      &:after {
        display: none;
      }
    }

    &:after {
      background: #efeef1;
    }
  }

  tbody tr:first-child td {
    border-top: none;
  }

  tbody tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-bottom-right-radius: 12px;
      }
    }
  }

  &__th,
  &__td {
    color: #001842;
    font-size: 1em;
    font-weight: 500;
    padding: 13px 12px 12px 12px;
    text-align: left;

    &--text-center {
      text-align: center;
    }
  }

  @media screen and (max-width: 810px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 30px 0;
    &-wrap {
      padding: 0 10px 20px 10px;
    }
    &-table {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 575px) {
    table {
      font-size: 12px;
    }
    thead th:first-child,
    tbody td:first-child {
      padding-left: 15px;
    }
    &__th,
    &__td {
      padding: 5px 12px 5px 10px;
    }
    &__elem {
      &--date {
        min-width: 127px;
      }
    }
    &-progress {
      &-count {
        &__el {
          font-size: 11px;
        }
      }
    }
  }
}

#almaty {
  .base-table {
    thead th {
      background: $almaty-blue-2;
      color: #fff;
    }
  }
}

#erg {
  .base-table {
    thead th {
      background: $erg-green-2;
    }
  }
}

#sultan {
  .base-table {
    thead th {
      color: #fff;
      background: $sultan-blue-2;
    }
  }
}
</style>
