<template>
  <div
    class="base-progress-line"
    :style="{ width, height }"
  >
    <div
      class="base-progress-line__item base-progress-line__completed"
      :style="{ backgroundColor: completed.color, width: widthCompleted }"
    />
    <div
      class="base-progress-line__item base-progress-line__total"
      :style="{ backgroundColor: total.color }"
    />
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'BaseProgressLine',
  props: {
    width: {
      type: String,
      default() {
        return '194px';
      },
    },
    height: {
      type: String,
      default() {
        return '6px';
      },
    },
    completed: {
      type: Object,
      default() {
        return {
          color: '#8654EF',
          count: 1,
        };
      },
    },
    total: {
      type: Object,
      default() {
        return {
          color: '#FF7070',
          count: 5,
        };
      },
    },
  },
  setup(props) {
    const widthCompleted = computed(
      () => `${Math.ceil((props.completed.count / props.total.count) * 100)}%`,
    );
    return {
      widthCompleted,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-progress-line {
  max-height: 100%;
  max-width: 100%;
  position: relative;

  &__item {
    border-radius: 4px;
    height: 100%;
  }

  &__completed {
    max-width: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}
</style>
