<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M320,176V136a40,40,0,0,0-40-40H88a40,40,0,0,0-40,40V376a40,40,0,0,0,40,40H280a40,40,0,0,0,40-40V336"
    style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"
  /><polyline
    points="384 176 464 256 384 336"
    style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"
  /><line
    x1="191"
    y1="256"
    x2="464"
    y2="256"
    style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"
  /></svg>
</template>

<style lang="scss" scoped>
#pisa {
  svg {
    stroke: #000;
  }
}

#erg {
  svg {
    stroke: #fff;
  }
}

#almaty {
  svg {
    stroke: #fff;
  }
}

#sultan {
  svg {
    stroke: #000;
  }
}
</style>
