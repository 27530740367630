<template>
  <div class="base-filter-panel">
    <div
      class="base-filter-panel__inner"
      :class="{ revers }"
    >
      <div class="base-filter-panel__group left">
        <slot name="left" />
      </div>
      <div class="base-filter-panel__group right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFilterPanel',
  props: {
    revers: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.base-filter-panel {
  font-family: $ibm-plex-sans;
  position: relative;
  @media (max-width: $mobile-width) {
    padding-top: 10px;
  }

  &__inner {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100vw;

    & .base-filter-panel__group.left {
      margin-right: 24px;

      @media (max-width: $mobile-width) {
        margin-bottom: 24px;
      }
    }

    @media (max-width: $mobile-width) {
      flex-direction: column;
      overflow-x: visible;
    }
  }

  .revers {
    @media (max-width: $mobile-width) {
      flex-direction: column-reverse;
    }
  }

  &__group {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 60%;
    align-items: flex-start;
    @media (max-width: $mobile-width) {
      max-width: calc(100vw - 30px);
    }
  }

  .right {
    justify-content: flex-end;
    @media (max-width: $mobile-width) {
      justify-content: space-between;
    }
  }

  .left {
    @media (max-width: $mobile-width) {
      flex-direction: row-reverse;
      justify-content: flex-end;

      & > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
