import axios from 'axios';
import cookie from 'vue-cookies';
import moment from 'moment';
import throttle from '~utils/throttle';

export const saveAnswers = store => store.subscribe((mutation, state) => {
  switch (mutation.type) {
    case 'unit/setAnswer':
      throttle(
        'saveAnswers',
        () => axios
          .post(
            '/api/answers',
            { testId: state.unit.exercise._id, answers: state.unit.answers },
          )
          .then(xhr => {
            const { isExpired } = xhr.data;
            if (isExpired) {
              store.dispatch('unit/fetchTest', state.unit.testId);
            }
          })
          .catch(e => console.error(e)),
      );
      break;
    case 'unit/setRating':
      throttle(
        'saveRatings',
        () => axios
          .post(
            '/api/rating',
            { testId: state.unit.exercise._id, rating: state.unit.ratings },
          )
          .catch(e => console.error(e)),
      );
      break;
    default:
    // Do nothing
  }
});

const getTranslatedFinishData = ({ locale, exercise, isTeacher }) => {
  const data = {
    ru: {
      isTeacher: {
        introduction: {
          topic: 'Проверка завершена',
          description: 'Оценки сохранены',
          links: [{ href: `/finish-checking/${exercise._id}`, label: 'Закончить оценку' }],
        },
        exercises: [],
      },
      isNotTeacher: {
        introduction: {
          topic: 'Вы выполнили все задания',
          description: 'Хотите закончить выполнение, чтобы проверить ответы?',
          links: [{ href: `/finish-test/${exercise._id}`, label: 'Закончить выполнение' }],
        },
        exercises: [],
      },
    },
    kz: {
      isTeacher: {
        introduction: {
          topic: 'Тексеру аяқталды',
          description: 'Бағалар сақталды',
          links: [{ href: `/finish-checking/${exercise._id}`, label: 'Бағалауды аяқтау' }],
        },
        exercises: [],
      },
      isNotTeacher: {
        introduction: {
          topic: 'Сіз барлық тапсырмаларды орындадыңыз',
          description: 'Жауаптарды тексеру үшін орындауды аяқтағыңыз келеді?',
          links: [{ href: `/finish-test/${exercise._id}`, label: 'Орындауды аяқтау' }],
        },
        exercises: [],
      },
    },
  };
  return isTeacher ? data[locale].isTeacher : data[locale].isNotTeacher;
};

const settings = {
  namespaced: true,
  state: {
    exercise: null,
    checking: false,
    readonly: false,
    answers: {},
    ratings: {},
    expired: false,
    isPreview: false,
    isTeacher: false,
    showrate: true,
    backLink: null,
  },
  mutations: {
    setExercise(state, {
      exercise, checking, readonly, isPreview = false, isTeacher = false, serverTime = new Date(),
    }) {
      const timeDiffMs = new Date() - serverTime;
      const timeDiffMin = timeDiffMs / 1000 / 60;
      const addTime = Math.abs(timeDiffMin) > 20 ? timeDiffMs : 0;

      if (exercise.started) {
        exercise.started = new Date(exercise.started);
        if (addTime) {
          exercise.started = moment(exercise.started).add(addTime, 'ms').toDate();
        }
      }
      if (exercise.finished) {
        exercise.finished = new Date(exercise.finished);
        if (addTime) {
          exercise.finished = moment(exercise.finished).add(addTime, 'ms').toDate();
        }
      }
      state.exercise = exercise;
      state.checking = checking;
      state.readonly = readonly;
      state.answers = exercise.answers || {};
      state.ratings = exercise.ratings || {};
      state.expired = exercise.isExpired || false;
      state.isPreview = isPreview;
      state.isTeacher = isTeacher;

      if (!isPreview && !exercise.checked) {
        state.exercise.unitsOrder.push('final__page');
        state.exercise.units.final__page = getTranslatedFinishData({
          isTeacher,
          exercise,
          locale: cookie.get('locale') ?? 'ru',
        });
      }
    },
    setAnswer(state, {
      unitName, id, name, value,
    }) {
      if (state.isPreview) {
        return;
      }

      const unitAnswers = state.answers[unitName] || [];
      const answer = unitAnswers[id] || {};
      answer[name] = value;

      unitAnswers[id] = answer;
      state.answers[unitName] = unitAnswers;
    },
    setRating(state, { unitName, id, value }) {
      if (state.isPreview) {
        return;
      }

      const ratings = state.ratings[unitName] || {};
      ratings[id] = value;
      state.ratings[unitName] = ratings;
    },
    expire(state) {
      if (state.isPreview) {
        return;
      }

      state.expired = true;
    },
    SET_BACKLINK(state, link) {
      state.backLink = link;
    },
  },
  getters: {
    getAnswer: state => (unitName, id, name) => {
      const unitAnswers = state.answers[unitName] || [];
      const pageAnswers = unitAnswers[id] || {};

      return pageAnswers[name] || null;
    },
    getRating: state => (unitName, id) => (state.ratings[unitName] || {})[id],
    blockSolving: state => {
      if (state.isPreview || state.exercise?.checked) {
        return false;
      }

      return (state.checking || state.isTeacher) ? false : state.expired;
    },
  },
  actions: {
    async fetchTest({ commit }, testId) {
      try {
        const { data } = await axios.get(`/api/test?testId=${testId}`);
        commit(
          'setExercise',
          {
            exercise: data.exercise,
            checking: data.checking,
            readonly: data.readonly,
            isTeacher: data.isTeacher,
            serverTime: new Date(data.st),
          },
        );
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async fetchUnitPreview({ commit }, unitName) {
      try {
        const { data } = await axios.get(`/api/test-preview?name=${unitName}`);
        commit(
          'setExercise',
          {
            exercise: data.exercise,
            checking: false,
            readonly: true,
            isPreview: true,
            isTeacher: true,
          },
        );
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};

export default settings;
