<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="9"
      fill="white"
    />
    <g opacity="0.6">
      <path
        d="M9.42833 14.8387L13.2853 11.4104C13.5486 11.1896 13.572 10.7533 13.3429 10.4973C13.1138 10.2414 12.6767 10.2156 12.4282 10.4528L9.64261 12.9304L9.64261 3.64284C9.64261 3.28781 9.35479 3 8.99978 3C8.64474 3 8.35694 3.28781 8.35694 3.64284V12.9304L5.57132 10.4528C5.32287 10.2156 4.88656 10.2477 4.65744 10.5036C4.42833 10.7596 4.45099 11.1896 4.71421 11.4104L8.57122 14.8387C8.88306 15.0626 9.16301 15.0447 9.42833 14.8387Z"
        fill="#001842"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowCircle',
};
</script>
