<template>
  <svg
    :height="height"
    :width="width"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <circle
      :cx="cx"
      :cy="cy"
      :r="r"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '12px',
    },
    height: {
      type: String,
      default: '12px',
    },
    cx: {
      type: Number,
      default: 6,
    },
    cy: {
      type: Number,
      default: 6,
    },
    r: {
      type: Number,
      default: 6,
    },
  },
};
</script>
