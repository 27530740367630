function deleteAllCookiesByName(name) {
  const cookiesArray = document.cookie.split('; ');
  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i];
    const [cookieName] = cookie.split('=');
    const trimmedCookieName = cookieName.trim();
    if (trimmedCookieName === name) {
      const pathSegments = window.location.pathname.split('/').filter(segment => segment !== '');
      for (let j = 0; j < pathSegments.length; j++) {
        const path = `/${pathSegments.slice(0, j + 1).join('/')}`;
        document.cookie = `${trimmedCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
      }
    }
  }
}

function setCookie(name, value, options = {}) {
  const defaults = { path: '/', SameSite: 'None', secure: true };

  const finalOptions = {
    ...defaults,
    ...options,
  };

  if (finalOptions.expires instanceof Date) {
    finalOptions.expires = finalOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.entries(finalOptions).forEach(([key, optionValue]) => {
    updatedCookie += `; ${key}`;
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
}

function getCookie(name) {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`));

  if (cookieValue) {
    return cookieValue.split('=')[1];
  }
  return null;
}

export { deleteAllCookiesByName, setCookie, getCookie };
