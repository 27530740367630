import _ from 'lodash';
import Grades from '~utils/services/Grades';
import Schools from '~utils/services/Schools';
import Users from '~utils/services/Users';
import { userRoles } from '~utils/globals';

const getInitialState = () => ({
  grades: null,
  schoolInfo: null,
  students: null,
  regionStudents: null,
  studentsPagination: null,
  isStudentsFiltered: false,
  isStudentsLoading: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    all: state => state.grades ?? [],
    has: state => name => state.grades.find(grade => grade.name === name) !== undefined,
    isNotLoaded: state => state.grades === null || state.schoolInfo === null,
    isGradesNotLoaded: state => state.grades === null,
    schoolInfo: state => state.schoolInfo,
    students: state => state.students,
    gradeName: state => state.students?.name,
    isEmpty: state => state.grades?.length === 0,
    isStudentsFiltered: state => state.isStudentsFiltered,
    isStudentsLoading: state => state.isStudentsLoading,
    isStudentsNotLoaded: state => state.students === null,
    isRegionStudentsNotLoaded: state => state.regionStudents === null,
    regionStudents: state => state.regionStudents ?? [],
    totalStudents: state => state.studentsPagination?.total,
    pagesCount: state => state.studentsPagination?.totalPages,
    perPage: state => state.studentsPagination?.limit,
  },
  mutations: {
    SET_GRADES(state, grades) {
      state.grades = grades;
    },
    SET_GRADES_SCHOOL(state, { school, grades }) {
      state.schoolInfo = school;
      state.grades = grades;
    },
    SET_GRADE_STUDENTS(state, students) {
      state.students = students;
    },
    SET_REGION_STUDENTS(state, { students, pagination }) {
      state.regionStudents = students;
      state.studentsPagination = pagination;
    },
    SET_STUDENTS_LOADING(state, isStudentsLoading) {
      state.isStudentsLoading = isStudentsLoading;
    },
    SET_STUDENTS_FILTERED(state, isStudentsFiltered) {
      state.isStudentsFiltered = isStudentsFiltered;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async FETCH_GRADES({ state, commit }, fetchAgain = false) {
      if (!state.grades || fetchAgain) {
        const grades = await Grades.fetch();
        commit('SET_GRADES', grades);
      }
    },
    async FETCH_GRADES_SCHOOL({ state, commit }, { fetchAgain = false, schoolId }) {
      if (!state.gradeSchool || fetchAgain) {
        const { school, grades } = await Schools.fetchGrades(schoolId);
        commit('SET_GRADES_SCHOOL', { school, grades });
      }
    },
    async FETCH_GRADE_STUDENTS({ commit }, gradeId) {
      commit('SET_STUDENTS_LOADING', true);
      const students = await Grades.fetchStudents(gradeId);
      commit('SET_GRADE_STUDENTS', students);
      commit('SET_STUDENTS_LOADING', false);
    },
    async FETCH_REGION_STUDENTS({ commit }, {
      regionRespondered, page, schoolId, filter,
    }) {
      commit('SET_STUDENTS_LOADING', true);
      const { users: students, pagination } = await Users.fetchByRegion({
        roles: [userRoles.student],
        regionId: regionRespondered,
        page,
        isArchived: 'false',
        schoolId,
        ...filter,
      });
      commit('SET_STUDENTS_FILTERED', !_.isEmpty(filter));
      commit('SET_REGION_STUDENTS', { students, pagination });
      commit('SET_STUDENTS_LOADING', false);
    },
  },
};
