import { userRoles, apiEndpoints } from '~utils/globals';
import { isContain } from '~helpers/general';
import { formatBySurname } from '~helpers/userNameFormatters';
import api from '~utils/api';
import Users from '~utils/services/Users';

export default class Teachers {
  static sort(teachers, type) {
    switch (type) {
      case 'asc':
        return teachers.slice()
          .sort((a, b) => a.surname?.localeCompare(b.surname));
      case 'des':
        return teachers.slice()
          .sort((a, b) => b.surname?.localeCompare(a.surname));
      default:
        throw new Error(`unknown sort type - ${type}`);
    }
  }

  static isResponder(teacher) {
    if (teacher.roles) {
      return teacher.roles.includes(userRoles.responderSchool);
    }

    return teacher.role === userRoles.responderSchool;
  }

  static getByName(teachers, searchName) {
    return teachers.filter(({ name, surname, patronymic }) => isContain(searchName, name, surname, patronymic));
  }

  static async create(data) {
    const response = await Users.create({ data, role: userRoles.teacher });
    return response.id;
  }

  static async fetchAllBySchool(schoolId) {
    const response = await api.get(`${apiEndpoints.schools}/${schoolId}?isArchived=false`);
    const formattedTeachers = response.data.teachers.map(teacher => ({
      ...teacher,
      fio: formatBySurname(teacher.surname, teacher.name, teacher.patronymic),
    }));
    return formattedTeachers;
  }

  static async fetchAllByCity(cityId) {
    return Users.fetchWithoutPagination({ role: userRoles.teacher, cityId });
  }

  static async fetchAll(options, sort = null) {
    return Users.fetch([userRoles.teacher, userRoles.responderSchool], options, sort);
  }

  static async fetchByAuthorizedRole(page, filter = {}, sort = null) {
    const authorizedUser = await Users.fetchAuthorizedUser();
    const { regionRespondered, role } = authorizedUser;

    filter.page = page;

    switch (role) {
      case userRoles.admin:
        break;
      case userRoles.responderRegion:
        filter.regions = regionRespondered;
        break;
      case userRoles.responderSchool:
        filter.schoolId = authorizedUser.school?._id;
        break;
      default:
        throw new Error(`user with this role can't fetch teachers - ${role}`);
    }

    return Teachers.fetchAll(filter, sort);
  }
}
