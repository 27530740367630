/* eslint import/no-named-as-default: 0 */
import 'element-plus/dist/index.css';
import { capitalize } from 'lodash';
import { createApp } from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import BaseComponents from '~components/Base';
import router from '@/router';
import App from './App';
import store from '@/store';
import i18n from './locales';
import ToastPlugin from '@/plugins/toast';

const app = createApp(App);
BaseComponents.forEach(component => {
  app.component(component.name, component);
});
app.config.unwrapInjectedRef = true;
app.use(store);
app.use(router);
app.use(i18n);
app.directive('capitalize', el => {
  const input = el.querySelector('input');
  if (input.value !== capitalize(input.value)) {
    input.value = capitalize(input.value);
    input.dispatchEvent(new Event('input', { bubbles: true }));
  }
});
app.use(ToastPlugin);
app.use(VueYandexMetrika, {
  id: 88049359,
  router,
  env: process.env.NODE_ENV,
});

// получение настроек юзера с бэка или откуда-то еще втч локали если будет хранится на бэке
store.dispatch('settings/getSettings');

app.mount('#app');
