import api2 from '@/services/api2';

export default {
  namespaced: true,
  state: {
    students: [],
  },
  getters: {
    students: state => state.students,
  },
  mutations: {
    setStudents(state, values) {
      state.students = values;
    },
  },
  actions: {
    async deleteStudents(empty, studentIds) {
      const { data } = await api2.delete(`/students?studentIds=${encodeURIComponent(JSON.stringify(studentIds))}`, studentIds);
      return data;
    },
  },
};
