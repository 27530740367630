<template>
  <button
    class="base-filter-button"
    :class="{
      'base-filter-button--has-icon': hasIcon,
      'base-filter-button--is-active': isActive,
    }"
    @click="toggleActive"
  >
    <span class="base-filter-button__text">
      <slot />
    </span>
    <BaseIcon
      v-if="hasIcon"
      class="base-filter-button__icon"
      :icon-name="iconName"
    />
  </button>
</template>

<script>
export default {
  name: 'BaseFilterPanelButton',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: {
    'update:modelValue': value => typeof value === 'boolean',
  },
  computed: {
    hasIcon() {
      return this.iconName !== null;
    },
    isActive() {
      return this.modelValue;
    },
  },
  methods: {
    toggleActive() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style scoped lang="scss">

.base-filter-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;

  font-weight: 500;
  font-size: 16px;
  color: $grey-dark;

  background-color: $grey;
  border: none;
  border-radius: 20px;
  cursor: pointer;

  transition: background-color 0.15s, color 0.15s, fill 0.5s;

  &:hover {
    background-color: darken($grey, 5);
  }

  &__icon {
    color: $grey-dark;
  }

  &--has-icon {
    .base-filter-button__text {
      margin-right: 10px;
    }
  }

  &--is-active {
    color: #FFFFFF;

    background-color: $pisa-purple;

    &:hover {
      background-color: lighten($pisa-purple, 5);
    }

    .base-filter-button__icon {
      color: #FFFFFF;
    }
  }
}
</style>
