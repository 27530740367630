import { isString } from 'lodash';

function copyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function compareObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function maskText(text, symbol = '*') {
  if (!isString(text)) {
    return null;
  }

  return symbol.repeat(text.length);
}

function isContain(substring, ...targets) {
  return targets.join(' ').toLowerCase().includes(substring.toLowerCase());
}

function hasDuplicates(target) {
  return new Set(target).size !== target.length;
}

function sortByUnicode(symbols) {
  return symbols.slice().sort((a, b) => a.localeCompare(b));
}

export {
  copyObject,
  compareObjects,
  maskText,
  isContain,
  hasDuplicates,
  sortByUnicode,
};
