<template>
  <div
    class="base-modal__container"
    @click.self="closeModal"
  >
    <div class="base-modal">
      <button
        class="base-modal__close-button"
        @click="closeModal"
      >
        <base-icon icon-name="XIcon" />
      </button>
      <div class="base-modal__content">
        <slot name="content" />
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
import { popModal } from 'jenesius-vue-modal';

export default {
  name: 'BaseModal',
  methods: {
    closeModal() {
      popModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.base-modal__container {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: grid;
  left: 0;
  overflow-y: scroll;
  place-items: center;
  position: fixed;
  right: 0;
  top: 0;
  cursor: default;
  z-index: 1000;
}

.base-modal {
  padding: 64px 5px 0 5px;
  position: relative;
  z-index: 1000;
}

.base-modal__content {
  background: #fff;
  border-radius: 20px;
}

.base-modal__close-button {
  padding: 0;
  background: #fff;
  border: 8px solid rgba(0, 0, 0, 0.7);
  border-radius: 30px;
  cursor: pointer;
  height: 60px;
  position: absolute;
  right: -60px;
  top: 0;
  width: 60px;
  &:active {
    transform: none;
  }
}

@media (max-width: 950px) {
  .base-modal__close-button {
    right: 0;
  }
}
</style>
