import axios from 'axios';

export default {
  namespaced: true,
  state: {
    exercises: null,
    themes: null,
    currentPage: 1,
    filteredExercises: null,
    themeFilters: [],
    nameFilter: '',
    selectedExercises: [],
  },
  getters: {
  },
  mutations: {
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_EXERCISES(state, exercises) {
      state.exercises = exercises;
    },
    SET_THEMES(state, themes) {
      state.themes = themes;
    },
    SET_THEME_FILTERS(state, filters) {
      state.themeFilters = filters;
    },
    SET_NAME_FILTER(state, filter) {
      state.nameFilter = filter;
    },
    SET_FILTERED_EXERCISES(state, exercises) {
      state.filteredExercises = exercises;
    },
    SET_SELECTED_EXERCISES(state, exercises) {
      state.selectedExercises = exercises;
    },
  },
  actions: {
    async FETCH_EXERCISES({ commit, state }) {
      if (state.exercises === null) {
        try {
          const response = await (axios('api/subject/list'));
          const { exercises, themes } = response.data;
          commit('SET_EXERCISES', exercises);
          commit('SET_THEMES', themes);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
