<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
  >
    <path
      d="M16.0731 4.61746H15.5131V3.42588C15.5131 1.8126 14.2005 0.5 12.5872 0.5H6.3613C4.74802 0.5 3.43542 1.8126 3.43542 3.42588V4.61746H2.92588C1.3126 4.61746 0 5.93006 0 7.54334V12.329C0 13.9423 1.3126 15.2549 2.92588 15.2549H3.43542V19.0608C3.43542 19.8543 4.0811 20.5 4.87461 20.5H14.0737C14.8672 20.5 15.5129 19.8543 15.5129 19.0608V15.2549H16.0728C17.6861 15.2549 18.9987 13.9423 18.9987 12.329V7.54334C18.9987 5.93006 17.6864 4.61746 16.0731 4.61746ZM4.47414 3.42588C4.47414 2.3853 5.32072 1.53872 6.3613 1.53872H12.587C13.6276 1.53872 14.4744 2.3853 14.4744 3.42588V4.61746H4.47414V3.42588ZM14.0737 19.4613H4.87461C4.6538 19.4613 4.47414 19.2816 4.47414 19.0608V12.8553H14.4744V14.7342C14.4744 14.7348 14.4741 14.7353 14.4741 14.7358C14.4741 14.7364 14.4744 14.7369 14.4744 14.7374V19.0608C14.4744 19.2816 14.2947 19.4613 14.0737 19.4613ZM17.9602 12.329C17.9602 13.3696 17.1137 14.2162 16.0731 14.2162H15.5131V12.855H16.2947C16.5816 12.855 16.814 12.6226 16.814 12.3357C16.814 12.0488 16.5816 11.8163 16.2947 11.8163H2.56548C2.2786 11.8163 2.04612 12.0488 2.04612 12.3357C2.04612 12.6226 2.2786 12.855 2.56548 12.855H3.43542V14.2162H2.92588C1.8853 14.2162 1.03845 13.3696 1.03845 12.329V7.54334C1.03845 6.50276 1.88503 5.65618 2.92588 5.65618H16.0731C17.1137 5.65618 17.9602 6.50276 17.9602 7.54334V12.329ZM5.09143 15.0585C5.09143 14.7716 5.3239 14.5392 5.61079 14.5392H13.2934C13.5803 14.5392 13.8128 14.7716 13.8128 15.0585C13.8128 15.3454 13.5803 15.5779 13.2934 15.5779H5.61079C5.3239 15.5776 5.09143 15.3451 5.09143 15.0585ZM13.8569 17.3589C13.8569 17.6458 13.6244 17.8783 13.3375 17.8783H5.65484C5.36796 17.8783 5.13548 17.6458 5.13548 17.3589C5.13548 17.072 5.36796 16.8395 5.65484 16.8395H13.3375C13.6244 16.8395 13.8569 17.072 13.8569 17.3589ZM16.7981 7.34271C16.7981 7.62959 16.5656 7.86207 16.2788 7.86207H14.704C14.4171 7.86207 14.1846 7.62959 14.1846 7.34271C14.1846 7.05582 14.4171 6.82335 14.704 6.82335H16.2788C16.5656 6.82335 16.7981 7.05582 16.7981 7.34271Z"
    />
  </svg>
</template>
