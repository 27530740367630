<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1776 0.646607C15.3135 -0.215536 13.9177 -0.215536 13.0536 0.646607L1.68739 11.965C1.64308 12.0092 1.62092 12.0313 1.59877 12.0755C1.59877 12.0755 1.59877 12.0976 1.57661 12.0976C1.55445 12.1418 1.5323 12.164 1.5323 12.2082V12.2303L0.0256656 17.1379C-0.0408035 17.381 0.0256655 17.6242 0.18076 17.801C0.313698 17.9337 0.468792 18 0.646043 18C0.712512 18 0.778981 18 0.84545 17.9779L5.742 16.4747H5.76416C5.80847 16.4526 5.85278 16.4526 5.87494 16.4305C5.87494 16.4305 5.8971 16.4305 5.8971 16.4084C5.94141 16.3862 5.98572 16.3641 6.00788 16.3199L17.3519 5.00154C18.216 4.13939 18.216 2.7467 17.3519 1.88456L16.1776 0.646607ZM1.66523 16.3199L2.48502 13.6893L4.324 15.5241L1.66523 16.3199ZM5.56475 14.883L3.1054 12.4513L11.7021 3.87412L14.1614 6.32791L5.56475 14.883ZM16.4214 4.02886L15.092 5.35524L12.6548 2.92355L13.9842 1.59718C14.3387 1.24348 14.8926 1.24348 15.2471 1.59718L16.4435 2.79091C16.7759 3.12251 16.7759 3.69727 16.4214 4.02886Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '18px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
