import Vuex from 'vuex';
import user from './modules/user';
import user2 from './modules/user2';
import settings from './modules/settings';
import unit, { saveAnswers } from './modules/Unit';
import menus from './modules/menus';
import grades from './modules/grades';
import exercisesCatalog from './modules/ExercisesCatalog';
import works from './modules/Works';
import responders from './modules/responders';
import locations from './modules/locations';
import teachers from './modules/teachers';
import teachers2 from './modules/teachers2';
import schools from './modules/schools';
import articles from './modules/articles';
import statistics from './modules/statistics';
import gradeAndStudents from './modules/gradeAndStudents';
import mail from './modules/mail';
import students from './modules/students';

const store = new Vuex.Store({
  modules: {
    settings,
    user,
    user2,
    unit,
    menus,
    grades,
    works,
    exercisesCatalog,
    responders,
    locations,
    teachers,
    teachers2,
    schools,
    articles,
    statistics,
    gradeAndStudents,
    mail,
    students,
  },
  state: {
    pageLoading: false,
  },
  mutations: {
    togglePageLoading(state) {
      state.pageLoading = !state.pageLoading;
    },
  },
  actions: {
    resetAllState({ commit }) {
      commit('articles/reset');
      commit('grades/reset');
      commit('locations/reset');
      commit('responders/reset');
      commit('schools/reset');
      commit('teachers/reset');
      commit('user/reset');
      commit('works/reset');
    },
  },
  plugins: [saveAnswers],
});

export default store;
