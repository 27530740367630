<template>
  <div class="base-search">
    <base-icon
      v-if="hasIcon"
      icon-name="Loupe"
      style="fill: #001842; opacity: 0.6"
    />
    <input
      class="base-search__field"
      :placeholder="placeholder"
      type="text"
      :value="modelValue"
      @input="updateModel($event.target.value)"
    >
  </div>
</template>

<script>
import { regExp } from '~utils/globals';

export default {
  name: 'BaseSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Найти',
    },
    modelValue: {
      type: String,
      default: '',
    },
    isWordsOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUppercase: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateModel(newValue) {
      let formattedValue = newValue;

      if (this.isWordsOnly) {
        formattedValue = formattedValue.replace(regExp.notRuSymbols, '');
      }

      if (this.isUppercase) {
        formattedValue = formattedValue.toUpperCase();
      }

      this.$emit('update:modelValue', formattedValue);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>

.base-search {
  display: flex;
  align-items: center;
  max-width: 462px;
  border-radius: 20px;
  border: 0;
  max-height: 37px;
  padding-left: 24px;
  background-color: $grey;
  &__field {
    color: $black;
  }

  &__field {
    font-family: $ibm-plex-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    border: unset;
    outline: unset;
    overflow: hidden;
    background-color: transparent;
    padding: 1px 10px;
    flex: 1;

    &::placeholder {
      color: $grey-dark;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }
}

#almaty {
  .base-search {
    background-color: $almaty-green-2;

    &__field {
      color: $black;
    }
  }
}
</style>
