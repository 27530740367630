import Locations from '~utils/services/Locations';

const getInitialState = () => ({
  regions: null,
  districts: null,
  cities: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    regions: state => state.regions ?? [],
    districts: state => state.districts ?? [],
    cities: state => state.cities ?? [],
    isRegionsNotLoaded: state => state.regions === null,
    isDistrictsNotLoaded: state => state.regions === null,
    isCitiesNotLoaded: state => state.regions === null,
    isEmpty: state => state.regions?.length === 0,
    getLocationById: (_state, getters) => id => getters.regions.find(region => region._id === id) ?? null,
    getLocationNameById: (_state, getters) => id => getters.getLocationById(id)?.name,
  },
  mutations: {
    set: (state, regions) => {
      state.regions = regions;
    },
    setDistricts(state, districts) {
      state.districts = districts;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    fetch: async ({ commit }) => {
      const regions = await Locations.fetch();
      commit('set', regions);
    },
    async fetchDistricts({ commit }, regionId) {
      const districts = await Locations.fetchDistricts(regionId);
      commit('setDistricts', districts);
    },
    async fetchCities({ commit }, districtId) {
      const cities = await Locations.fetchCities(districtId);
      commit('setCities', cities);
    },
  },
};
