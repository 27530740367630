import axios from 'axios';
import * as domain from '../../../config';

const api = axios.create({
  baseURL: domain.APP_URL,
  withCredentials: true,
});

api.interceptors.request.use(config => {
  config.headers.APP_URL = domain.APP_URL;
  config.headers.APP_URL_OS = domain[window.location.origin].APP_URL_OS;
  config.headers.APP_URL_LOGIN_OS = domain[window.location.origin].APP_URL_LOGIN_OS;
  return config;
}, error => Promise.reject(error));

export default api;
