module.exports = {
  LOCALE: process.env.LOCAL,
  APP_URL: process.env.APP_URL,
  APP_V2_URL: process.env.APP_V2_URL,
  mail: {
    from: process.env.MAIL_FROM,
  },
  mailer: {
    host: process.env.MAILER_HOST,
    port: process.env.MAILER_PORT,
    auth: {
      user: process.env.MAILER_AUTH_USER,
      pass: process.env.MAILER_AUTH_PASS,
    },
  },

  'https://fl.amansultan.kz': {
    APP_URL_OS: 'https://amansultan.kz',
    APP_URL_LOGIN_OS: 'https://login.amansultan.kz',
  },
  'https://fl.2035school.ru': {
    APP_URL_OS: 'https://2035school.ru',
    APP_URL_LOGIN_OS: 'https://login.2035school.ru',
  },
  'http://localhost:5005': {
    APP_URL_OS: 'http://localhost:8080',
    APP_URL_LOGIN_OS: 'http://localhost:8642',
  },
  'https://preprod.fl.2035school.ru': {
    APP_URL_OS: '',
    APP_URL_LOGIN_OS: '',
  }
};
