import cookie from 'vue-cookies';
import api from '~utils/api';
import config from '../../../../config';
import { apiEndpoints } from '~utils/globals';
import { isContain, sortByUnicode } from '~helpers/general';

export default class Grades {
  static #lettersByLocale = {
    ru: ['Ё', ...Array.from({ length: 32 }, (_, idx) => String.fromCharCode(idx + 1040))],
    kz: ['Ә', 'Ғ', 'Қ', 'Ң', 'Ө', 'Ү', 'Ұ', 'Һ', 'І'],
  }

  static numbersWithId = Array.from({ length: 11 }, (_, idx) => ({ id: String(idx), name: idx + 1 }));

  static numbers = Array.from({ length: 11 }, (_, i) => i + 1);

  static get letters() {
    const isKzLocale = cookie.get('locale') === 'kz';
    if (isKzLocale) {
      return sortByUnicode([...Grades.#lettersByLocale.ru, ...Grades.#lettersByLocale.kz]);
    }
    return sortByUnicode(Grades.#lettersByLocale.ru);
  }

  static async fetch() {
    // await api.post(`${window.location.origin}/updatestudentos`, {
    //   osDomain: config[window.location.origin].APP_URL_OS,
    // });

    const response = await api.get(`${apiEndpoints.grades}/list`);
    return response.data.grades.map(grade => ({ ...grade, students: grade.students?.filter(id => id !== null) ?? [] }));
  }

  static async fetchStudents(gradeId) {
    const res = await api.patch(`${apiEndpoints.grades}/${gradeId}`, { domainOS: config[window.location.origin].APP_URL_OS });
    return res.data.grade;
  }

  static async updateGradeName({ gradeId, gradeName }) {
    const res = await api.patch(`${apiEndpoints.grades}/${gradeId}`, { name: gradeName });
    return res.data.message;
  }

  static async createGrade(data) {
    const res = await api.post(`${apiEndpoints.grades}`, { ...data });
    return res.data;
  }

  static async archiveGrade(gradeId) {
    await api.post(`${apiEndpoints.grades}/${gradeId}`);
  }

  static async addStudent({ gradeId, studentsID }) {
    const res = await api.patch(`${apiEndpoints.grades}/${gradeId}/students`, { studentsID });
    return res.data;
  }

  static async deleteStudent({ gradeId, studentsID }) {
    const res = await api.patch(`${apiEndpoints.grades}/${gradeId}/students`, { isRemove: true, studentsID });
    return res.data;
  }

  static async setFavorite({ newFavoriteGrades }) {
    const response = await api.put(apiEndpoints.favoriteGrade, { favoriteGrades: newFavoriteGrades });
    return response.data.favoriteGrades;
  }

  static sortByName(grades) {
    return grades.slice()
      .sort((a, b) => a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }));
  }

  static groupByNumbers(grades) {
    const sortedGrades = Grades.sortByName(grades);

    const groupedGrades = sortedGrades.reduce((acc, currentGrade, idx, array) => {
      const gradeNumber = Grades.getNumber(currentGrade.name);

      const isGradeAdded = acc.some(gradeList => gradeList.gradeNumber === gradeNumber);
      if (isGradeAdded) {
        return acc;
      }

      const allNumberGrades = array.filter(grade => Grades.getNumber(grade.name) === gradeNumber);
      acc.push({
        gradeNumber,
        grades: allNumberGrades,
      });

      return acc;
    }, []);

    return groupedGrades;
  }

  static getNumber(gradeName) {
    return parseInt(gradeName.slice(0, -1), 10);
  }

  static getLetter(gradeName) {
    return gradeName.charAt(gradeName.length - 1).toUpperCase();
  }

  static getByLetter(grades, gradeLetter) {
    return grades.filter(grade => isContain(gradeLetter, grade.name));
  }

  static getByNumbers(grades, gradeNumbers) {
    if (!Array.isArray(gradeNumbers)) {
      throw new TypeError('invalid gradeNumbers type');
    }

    if (gradeNumbers.length === 0) {
      return grades;
    }

    return grades.filter(grade => {
      const number = Grades.getNumber(grade.name);
      return gradeNumbers.includes(number);
    });
  }

  static getNumbers(grades) {
    const gradeNumbers = grades.map(grade => Grades.getNumber(grade.name));
    const gradeNumbersWithoutDuplicates = gradeNumbers
      .reduce((numbers, gradeNumber) => {
        if (numbers.includes(gradeNumber)) {
          return numbers;
        }
        return [...numbers, gradeNumber];
      }, [])
      .sort((a, b) => a - b);
    return gradeNumbersWithoutDuplicates.map(grade => ({ id: grade }));
  }
}
