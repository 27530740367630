<template>
  <div class="admin-modal-multiselect">
    <div
      v-if="label"
      class="admin-modal-multiselect__label"
    >
      {{ label }}
    </div>
    <multiselect
      v-model="subject"
      :class="{ selected: subject }"
      :disabled="!options.length"
      :label="view"
      :options="options"
      :placeholder="placeholder"
      :searchable="isSearchable"
      :track-by="trackBy"
      :allow-empty="canUnselectAll"
    >
      <template #tag="tag">
        {{ `${tag.option[view]}, ` }}
      </template>
      <template #noOptions>
        Список пуст
      </template>
      <template #noResult>
        Нет результатов
      </template>
    </multiselect>
  </div>
</template>

<script>
import { computed } from 'vue';
import Multiselect from 'vue-multiselect';

export default {
  name: 'BaseFilterPanelSelect',
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    canUnselectAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSearchable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const subject = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    return {
      subject,
    };
  },
};
</script>

<style lang="scss" scoped>
.admin-modal-multiselect {
  &__label {
    color: $black;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
:deep {
  .multiselect {
    display: flex;
    width: 235px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    padding: 8px 16px;

    outline: none;
    text-overflow: ellipsis;

    background: $grey;

    border-radius: 20px;
    max-height: 37px;
    &--active {
      background: linear-gradient(180deg, #8654ef 0%, #7343d7 100%);

      .multiselect__select {
        opacity: 1;
        transform: rotate(180deg);
        filter: invert(100%);
      }
      .multiselect__input {
        color: #fff;
      }
      ::placeholder {
        color: #fff;
      }
    }
    &--disabled {
      background: #f8f8f8;
      border: 1px solid #efeef1;
      cursor: not-allowed;
    }

    &.selected {
      background: linear-gradient(180deg, #8654ef 0%, #7343d7 100%);

      .multiselect__tags {
        color: #fff;
      }

      .multiselect__select {
        filter: invert(100%);
      }

      .multiselect__input {
        color: #fff;
      }
      ::placeholder {
        color: #fff;
      }
    }

    &__tags {
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      input {
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 21px;
      }
    }
    &__input {
      background: transparent;
    }
    &__select {
      margin-left: 10px;
      width: 10px;
      height: 10px;
      background: url('~@/assets/icons/botArrow.svg') no-repeat center;
    }

    &__content-wrapper {
      position: absolute;
      top: 110%;
      z-index: 1000;
      border: 1px solid $pisa-purple;
      left: 0;
      right: 0;
      border-radius: 8px;
      padding: 3px 5px;
      min-width: 235px;
      width: min-content;
      background: #fff;
      font-size: 16px;
    }
    &__content {
      list-style: none;
      color: $black;
      max-height: 161px;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        width: 3px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        height: 27px;
        background-color: #f5f5f5;
        margin-right: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $pisa-purple;
      }
    }

    &__option {
      display: flex;
      align-items: center;
      padding: 7px;

      & span {
        padding-left: 5px;
      }

      &:before {
        display: block;
        content: '';
        top: 0;
        bottom: 0;
        min-width: 15px;
        height: 15px;
        border: 1px solid $pisa-purple;
        border-radius: 100%;
      }
      &--selected {
        &:before {
          max-width: 5px;
          min-width: 5px;
          height: 5px;
          border: 6px solid $pisa-purple;
        }
      }
    }
  }
}

#almaty .admin-modal-select {
  :deep .multiselect {
    border-color: rgb(36, 203, 159, 0.5);

    &--active {
      border-color: $almaty-green;
    }
    &__select {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='23' viewBox='0 0 11 23' fill='none'%3E%3Cpath d='M1 17.0562L5.5 21.5005L10 17.0562' stroke='%2324cb9f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 5.94434L5.5 1.50003L1 5.94434' stroke='%2324cb9f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
        no-repeat center;
    }

    &__content-wrapper {
      border-color: $almaty-green;
    }
    &__content {
      &::-webkit-scrollbar-thumb {
        background-color: $almaty-green;
      }
    }

    &__option {
      &:before {
        border-color: $almaty-green;
      }
      &--selected {
        &:before {
          border-color: $almaty-green;
        }
      }
    }
  }
}

#erg .admin-modal-select {
  :deep .multiselect {
    border-color: rgb(3, 42, 244, 0.5);

    &--active {
      border-color: $erg-blue;
    }
    &__select {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='23' viewBox='0 0 11 23' fill='none'%3E%3Cpath d='M1 17.0562L5.5 21.5005L10 17.0562' stroke='%23032af4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 5.94434L5.5 1.50003L1 5.94434' stroke='%23032af4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
        no-repeat center;
    }

    &__content-wrapper {
      border-color: $erg-blue;
    }
    &__content {
      &::-webkit-scrollbar-thumb {
        background-color: $erg-blue;
      }
    }

    &__option {
      &:before {
        border-color: $erg-blue;
      }
      &--selected {
        &:before {
          border-color: $erg-blue;
        }
      }
    }
  }
}

#sultan .admin-modal-select {
  :deep .multiselect {
    border-color: rgb(52, 133, 255, 0.5);

    &--active {
      border-color: $sultan-blue;
    }
    &__select {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='23' viewBox='0 0 11 23' fill='none'%3E%3Cpath d='M1 17.0562L5.5 21.5005L10 17.0562' stroke='%233485ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 5.94434L5.5 1.50003L1 5.94434' stroke='%233485ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
        no-repeat center;
    }

    &__content-wrapper {
      border-color: $sultan-blue;
    }
    &__content {
      &::-webkit-scrollbar-thumb {
        background-color: $sultan-blue;
      }
    }

    &__option {
      &:before {
        border-color: $sultan-blue;
      }
      &--selected {
        &:before {
          border-color: $sultan-blue;
        }
      }
    }
  }
}
</style>
