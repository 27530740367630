<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.41204 0L0.706232 8.70568L0 12L3.29432 11.2938L12 2.58796L9.41204 0ZM0.696253 10.7368L1.07217 8.98257L3.01743 10.9278L1.24358 11.308L0.696253 10.7368Z"
      fill="#B8B3C1"
    />
  </svg>
</template>
