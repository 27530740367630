import _ from 'lodash';
import { userRoles } from '~utils/globals';
import Users from '~utils/services/Users';
import Teachers from '~utils/services/Teachers';

const getInitialState = () => ({
  teachers: null,
  isLoading: false,
  pagination: null,
  sort: { fio: 'asc' },
  allTeachers: null,
  isFiltered: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    all: state => state.teachers ?? [],
    page: state => state.pagination?.skip,
    total: state => state.pagination?.total,
    pagesCount: state => state.pagination?.totalPages,
    pagination: state => state.pagination,
    perPage: state => state.pagination?.limit,
    currentPage: state => state.pagination?.page,
    isNotLoaded: state => state.teachers === null || state.pagination === null,
    isEmpty: state => state.teachers?.length === 0,
    isFiltered: state => state.isFiltered,
    isLoading: state => state.isLoading,
  },
  mutations: {
    set: (state, { teachers, pagination }) => {
      state.teachers = teachers;
      state.pagination = pagination;
    },
    remove: (state, id) => {
      state.teachers = state.teachers.filter(teacher => teacher._id !== id);
    },
    add: (state, teacher) => {
      state.teachers.push(teacher);
    },
    addMany: (state, { teachers }) => {
      state.teachers.push(...teachers);
    },
    update: (state, newTeacherData) => {
      const teacherIdx = state.teachers.findIndex(({ _id }) => newTeacherData._id === _id);
      state.teachers[teacherIdx] = { ...state.teachers[teacherIdx], ...newTeacherData };
    },
    setLoading: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    setFiltered: (state, isFiltered) => {
      state.isFiltered = isFiltered;
    },
    setSort: (state, sort) => {
      state.sort = sort;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async fetch({ commit, state }, page) {
      commit('setLoading', true);
      const { users, pagination } = await Teachers.fetchByAuthorizedRole(page, {}, state.sort);
      commit('set', { teachers: users, pagination });
      commit('setFiltered', false);
      commit('setLoading', false);
    },
    async fetchFiltered({ commit, state }, { page, ...filter }) {
      commit('setLoading', true);
      const { users, pagination } = await Teachers.fetchByAuthorizedRole(page, filter, state.sort);
      commit('set', { teachers: users, pagination });
      commit('setFiltered', !_.isEmpty(filter));
      commit('setLoading', false);
    },
    async remove({ commit }, id) {
      await Users.remove(id);
      commit('remove', id);
    },
    async create({ commit }, data) {
      await Users.create({
        role: userRoles.teacher,
        data,
      });
      commit('add', data);
    },
    async update({ commit }, data) {
      await Users.update(data);
      commit('update', data);
    },
  },
};
