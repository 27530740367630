import { getCookie } from '@/helpers/cookie';
import { setInitialLocale } from '@/helpers';

const location = window.location.host;
const siteVersion = window.localStorage.getItem('siteVersion');

setInitialLocale();

const settings = {
  'fl.2035school.ru': {
    locale: getCookie('locale') || 'ru',
    siteVersion: 'pisa',
  },
  'preprod.fl.2035school.ru': {
    locale: getCookie('locale') || 'ru',
    siteVersion: 'sultan',
  },
  'fl.amansultan.kz': {
    locale: getCookie('locale') || 'kz',
    siteVersion: 'sultan',
  },
  default: {
    locale: getCookie('locale') || 'ru',
    siteVersion: siteVersion || 'pisa',
  },
};

const currentSettings = settings[location] || settings.default;

export default {
  namespaced: true,

  state: {
    ...currentSettings,
  },
  getters: {
    siteVersion: state => state.siteVersion,
    locale: state => state.locale,
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
  },
  actions: {
    getSettings({ commit }) {
      commit('SET_LOCALE', 'ru');
    },
  },
};
