import { createVNode, render } from 'vue';
import Component from './Component.vue';
const toastStack = [];
const maxCountVisibleToast = 3;
function renderComponent({ message, error, showCloseBtn }) {
  const toastVNode = createVNode(Component, {
    message,
    error,
    showCloseBtn,
  });

  const toastContainer = document.createElement('div');
  render(toastVNode, toastContainer);
  const instance = toastVNode.component;

  const parentContainer = document.body;
  parentContainer.appendChild(toastContainer);

  return instance;
}

function deleteFirstToast() {
  const instanceObj = toastStack[0];
  clearTimeout(instanceObj.timerId);
  instanceObj.instance.ctx.dismiss();
  toastStack.shift();
}

export default {
  install(app) {
    app.config.globalProperties.$toast = (message, { delay = 3000, error = false, showCloseBtn = false } = {}) => {
      const instance = renderComponent({ message, error, showCloseBtn });
      if (toastStack.length >= maxCountVisibleToast) deleteFirstToast();
      const timerId = setTimeout(() => {
        instance.ctx.dismiss();
      }, delay);
      toastStack.push({ timerId, instance });
    };
  },
};
