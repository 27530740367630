import axios from 'axios';
import config from '../../../config';

const api2 = axios.create({
  baseURL: `${config.APP_URL}/api/v2`,
  withCredentials: true,
});
api2.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    console.error(error.response.data.error);
  } else if (error.request) {
    console.error("Проблема с запросом: ", error.request);
  } else {
    console.error("Error", error.message);
  }
  return Promise.reject(error);
});

export default api2;
