import Schools from '~utils/services/Schools';

const getInitialState = () => ({
  schools: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    all: state => state.schools ?? [],
    isNotLoaded: state => state.schools === null,
    isEmpty: state => state.schools?.length === 0,
    getById: state => id => state.schools?.find(({ _id }) => _id === id),
    names: (_state, getters) => Schools.getNames(getters.all),
    getName: (_state, getters) => id => getters.getById(id)?.name,
  },
  mutations: {
    set: (state, schools) => {
      state.schools = schools;
    },
    add: (state, school) => {
      state.schools.push(school);
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    fetch: async ({ commit }) => {
      const schools = await Schools.fetch();
      commit('set', schools);
    },
    create: async ({ dispatch }, { name, responderIds }) => {
      await Schools.create({ name, responderIds });
      await dispatch('fetch');
    },
    async DELETE_SCHOOL(_context, { id }) {
      await Schools.delete(id);
    },
  },
};
