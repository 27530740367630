<template>
  <div
    class="base-checkbox"
    :class="`theme--${theme}`"
    @click="toggleChecked"
  >
    <div
      class="base-checkbox__icon"
      :class="{ checked: modelValue }"
    >
      <base-icon
        v-if="modelValue"
        class="checked-checkbox"
        icon-name="CheckedCheckbox"
      />
    </div>
    <label>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    toggleChecked() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>

.base-checkbox {
  display: flex;
  align-items: center;
  user-select: none;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #dddddd;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    padding: 1px;
    cursor: pointer;

    transition: background-color 0.25s, border 0.25s;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.base-svg.checked-checkbox {
  color: #fff;
  fill: currentColor !important;
}

.theme {
  &--primary {
    .base-checkbox {
      &__icon {
        &.checked {
          background: $pink;
          border-color: $pink;
        }
      }
    }
  }
  &--admin {
    .base-checkbox {
      &__icon {
        &.checked {
          background: $pisa-purple;
          border-color: $pisa-purple;
        }
      }
    }
  }

  &--login {
    .base-checkbox {
      &__icon {
        &.checked {
          background: #fd4d26;
          border-color: #fd4d26;
        }
      }
    }
  }
}
</style>
