<template>
  <component
    :is="tag"
    class="base-btn"
    :class="[
      `theme--${theme}`,
      `size--${size}`,
      { 'base-btn--is-filled': isFilled },
    ]"
    :disabled="disabled || isLoading"
    :href="href"
    :to="to"
    :type="type"
  >
    <slot />
    <transition name="fade">
      <BaseSpinner
        v-if="isLoading"
        size="34px"
        class="base-btn__spinner"
      />
    </transition>
  </component>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BaseButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    to: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    theme: {
      type: [String, Array],
      default: 'primary',
    },
    size: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: null,
    },
    isFilled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { settings } = useStore().state;
    const siteVersion = computed(() => settings.siteVersion);
    return {
      siteVersion,
    };
  },
};
</script>

<style lang="scss" scoped>

.base-btn {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  overflow: hidden;
  color: #fff;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  text-decoration: none;
  // vertical-align: middle;
  // border: 2px solid transparent;

  outline: none;
  cursor: pointer;

  user-select: none;

  font-size: 18px;
  line-height: 24px;
  padding: 8px 28px;
  border-radius: 20px;

  transition: box-shadow 0.25s, opacity 0.25s, background-color 0.25s,
    color 0.25s, transform 0.25s;

  &--is-filled {
    width: 100%;
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 20px rgba(134, 84, 239, 0.45);
  }

  &.size {
    &--secondary {
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      padding: 10px 16px;
      border-radius: 12px;
    }
  }

  &:active {
    transform: translateY(2px);
  }
  &:hover {
    color: #fff;
  }

  &::after {
    position: absolute;
    left: -10%;
    top: -10%;
    right: -10%;
    bottom: -10%;

    content: '';

    background-color: transparent;

    transition: background-color 0.25s;
  }

  &:disabled {
    cursor: not-allowed !important;
    &::after {
      background-color: rgba(white, 0.5);
    }

    &:hover {
      box-shadow: none !important;
    }
  }

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;

    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}

.theme {
  &--none {
    background: none;
  }
}
// color-themes
.theme {
  &--primary {
    background: $pisa-purple;
  }
  &--primary-outline {
    color: $pisa-purple;

    border: 2px solid $pisa-purple;
    background: white;

    &:hover {
      color: $pisa-purple;
    }
  }
  &--login {
    background: #5811f0;
  }
  &--catalog {
    background: $pink;
  }
  &--warning {
    background: #f9a620;

    &:hover {
      box-shadow: 0 0 20px rgba(#f9a620, 0.45) !important;
    }
  }
  &--danger {
    background: #95190c;

    &:hover {
      box-shadow: 0 0 20px rgba(#95190c, 0.45) !important;
    }
  }
}

#almaty {
  .theme {
    &--primary,
    &--catalog,
    &--login {
      background-color: $almaty-green;
    }
    &--primary-outline {
      color: $almaty-green;

      border: 2px solid $almaty-green;
      background: white;

      &:hover {
        box-shadow: 0 0 20px rgba($almaty-green, 0.45) !important;
      }
    }
  }
}

#erg {
  .theme {
    &--primary,
    &--catalog {
      background: $erg-blue;
    }
    &--primary-outline {
      color: $erg-blue;

      border: 2px solid $erg-blue;
      background: white;

      &:hover {
        box-shadow: 0 0 20px rgba($erg-blue, 0.45) !important;
      }
    }
    &--login {
      background: #00b6d2;
    }
  }
}

#sultan {
  .theme {
    &--catalog {
      background: $sultan-blue-4;
    }
    &--primary,
    &--login {
      background: $sultan-blue;
    }
    &--primary-outline {
      color: $sultan-blue;

      border: 2px solid $sultan-blue;
      background: white;

      &:hover {
        box-shadow: 0 0 20px rgba($sultan-blue, 0.45) !important;
      }
    }
  }
}

#erg,
#sultan,
#almaty,
#pisa {
  .theme {
    &--pink {
      background: $pink;
    }
  }
  .negative-work,
  .positive-work {
    background: transparent;
  }
}
</style>
