import api2 from '@/services/api2';

export default {
  namespaced: true,
  state: {
    users: [],
    usersWithGrade: [],
  },
  getters: {
  },
  mutations: {
    setUsers(state, values) {
      state.users = values;
    },
    setUsersWithGrade(state, values) {
      state.usersWithGrade = values;
    },
  },
  actions: {
    async parseStudentsFromXlsx({ commit }, file) {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await api2.post('/students/excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      commit('setUsers', data);
      return data;
    },
    async createStudentsInGrade(empty, { users, gradeId }) {
      const { data } = await api2.post('/students', { users, gradeId });
      return data;
    },

    async parseStudentsWithGradeFromXlsx({ commit }, file) {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await api2.post('/grade/students/excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      commit('setUsersWithGrade', data);
      return data;
    },
    async createStudentsWithGrade(empty, { users }) {
      const { data } = await api2.post('/grade/students', users);
      return data;
    },
  },
};
