import api2 from '@/services/api2';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async checkExistUserByField(empty, { field, value }) {
      return api2.get(`/user/field=${field}?value=${value}`);
    },
    async createStudentsWithGrade(empty, { users }) {
      const { data } = await api2.post('/grade/students', users);
      return data;
    },
  },
};
