<template>
  <div class="locale-changer">
    <span class="me-10">{{ `${$t('Язык')}: ` }}</span>
    <label
      v-for="locale in $i18n.availableLocales"
      :key="`locale=${locale}`"
      class="locale-changer__locale"
      :class="{ currentLocale: $i18n.locale === locale }"
    >
      {{ locale }}
      <input
        v-model="$i18n.locale"
        :value="locale"
        type="radio"
        @change="changeLocale"
      >
    </label>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { deleteAllCookiesByName, setCookie } from '@/helpers/cookie';
import { getCurrentRootDomain } from '@/helpers';

export default {
  name: 'TheLocaleChanger',
  setup() {
    const store = useStore();
    const { siteVersion } = store.state.settings;

    function changeLocale(e) {
      deleteAllCookiesByName('locale');
      const { value } = e.target;
      const domain = getCurrentRootDomain();
      setCookie('locale', value, { domain });
      window.location.reload();
    }

    return {
      siteVersion,
      changeLocale,
    };
  },
};
</script>

<style lang="scss" scoped>

.locale-changer {
  display: flex;
  height: fit-content;

  &__locale:not(:last-child) {
    margin-right: 10px;
  }

  label {
    cursor: pointer;
    transition: 0.3s;
  }

  input {
    display: none;
  }
}

#pisa {
  .locale-changer {
    label {
      &:hover {
        color: $pisa-purple;
      }

      &.currentLocale {
        color: $pisa-purple;
      }
    }
  }
}

#almaty {
  .locale-changer {
    label {
      &:hover {
        color: $almaty-green;
      }

      &.currentLocale {
        color: $almaty-green;
      }
    }
  }
}

#erg {
  .locale-changer {
    label {
      &:hover {
        color: $erg-blue;
      }

      &.currentLocale {
        color: $erg-blue;
      }
    }
  }
}

#sultan {
  .locale-changer {
    label {
      &:hover {
        color: $sultan-blue;
      }
      &:first-child {
        border-right: 2px solid $black;
      }

      &.currentLocale {
        color: $sultan-blue;
      }
    }
  }
}
</style>
