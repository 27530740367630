<template>
  <div class="admins-fp-select">
    <multiselect
      v-model="subject"
      :class="{ selected: subject?.length }"
      :close-on-select="false"
      :disabled="disabled"
      :label="view"
      :multiple="true"
      :options="fullOptions"
      :placeholder="placeholder"
      :searchable="false"
      :track-by="trackBy"
    >
      <template #tag="tag">
        {{ `${tag.option[view]}, ` }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import { computed } from 'vue';
import Multiselect from 'vue-multiselect';

export default {
  name: 'BaseFilterPanelMultiSelectTag',
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    modelValue: {
      type: [String, Array, Object],
      default: null,
    },
    // view отвечает за отображение ключа из массива объектов.
    view: {
      type: String,
      default: null,
      require: true,
    },
    // trackBy отвечает за отслеживание ключа.
    trackBy: {
      type: String,
      default: 'id',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  computed: {
    subject: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    fullOptions() {
      if (this.modelValue?.length > 0) {
        let arr = this.options;
        this.modelValue.forEach(item => {
          arr.splice(arr.indexOf(item), 1);
          arr.unshift(item);
        });
        return arr;
      } else {
        return this.options;
      }
    },
    disabled() {
      if (this.isDisabled) {
        return this.isDisabled;
      } else {
        return this.options ? !this.options.length : false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
:deep .multiselect {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 12px;
  position: relative;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  background: $grey;
  padding: 8px 16px;
  border-radius: 20px;

  transition: background-color 0.25s;

  &:hover {
    background-color: darken($grey, 5);
  }

  &__select {
    transition: transform 0.25s;
  }

  &.selected {
    background: linear-gradient(180deg, #8654ef 0%, #7343d7 100%);
    .multiselect__tags {
      color: #fff;
    }
    .multiselect__select {
      filter: invert(100%);
    }
  }
  &--active {
    .multiselect__select {
      transform: rotate(180deg);
    }
  }

  &__tags {
    color: $black;
    white-space: nowrap;
    max-width: 200px;
  }
  &__tags-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__select {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    background: url('~@/assets/icons/botArrow.svg') no-repeat center;
  }

  &__content-wrapper {
    position: absolute;
    top: 110%;
    z-index: 1000;
    border: 1px solid $pisa-purple;
    left: 0;
    right: 0;
    border-radius: 8px;
    padding: 5px 10px 5px 5px;
    min-width: 235px;
    width: min-content;
    background: #fff;
    font-size: 16px;
  }
  &__content {
    list-style: none;
    color: $black;
    width: 98%;
    max-height: 161px;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      width: 3px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 27px;
      background-color: #f5f5f5;
      margin-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $pisa-purple;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 7px;

    & span {
      padding-left: 5px;
    }

    &:before {
      display: block;
      content: '';
      top: 0;
      bottom: 0;
      min-width: 15px;
      height: 15px;
      border: 1px solid $pisa-purple;
      border-radius: 5px;
    }
    &--selected {
      &:before {
        background: $pisa-purple url('~@/assets/check.svg') no-repeat center;
        background-size: 12px;
      }
    }
  }

  &--disabled {
    opacity: 0.7;
    cursor: default;
  }
}

#almaty {
  .admins-fp-select {
    :deep .multiselect {
      &.selected {
        background: linear-gradient(#24cb9f, #11ab82);
        .multiselect__tags {
          color: #fff;
        }
      }

      &__content-wrapper {
        border-color: $almaty-green;
      }

      &__content {
        &::-webkit-scrollbar-thumb {
          background-color: $almaty-green;
        }
      }
      &__option {
        &:before {
          border: 1px solid $almaty-green;
        }
        &--selected {
          &:before {
            border: 6px solid $almaty-green;
          }
        }
      }
    }
  }
}

#erg {
  .admins-fp-select {
    :deep .multiselect {
      &.selected {
        background: linear-gradient(#032af4, #0719a6);
        .multiselect__tags {
          color: #fff;
        }
      }

      &__content-wrapper {
        border-color: $erg-blue;
      }

      &__content {
        &::-webkit-scrollbar-thumb {
          background-color: $erg-blue;
        }
      }
      &__option {
        &:before {
          border: 1px solid $erg-blue;
        }
        &--selected {
          &:before {
            border: 6px solid $erg-blue;
          }
        }
      }
    }
  }
}

#sultan {
  .admins-fp-select {
    :deep .multiselect {
      &.selected {
        background: linear-gradient(#3485ff, #0061f1);

        .multiselect__tags {
          color: #fff;
        }
      }
      &__content-wrapper {
        border-color: $sultan-blue;
      }

      &__content {
        &::-webkit-scrollbar-thumb {
          background-color: $sultan-blue;
        }
      }
      &__option {
        &:before {
          border: 1px solid $sultan-blue;
        }
        &--selected {
          &:before {
            border: 6px solid $sultan-blue;
          }
        }
      }
    }
  }
}
</style>
