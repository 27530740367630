<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M0 7.5C0 11.6341 3.36585 15 7.5 15C11.6341 15 15 11.6341 15 7.5C15 3.36585 11.6341 0 7.5 0C3.36585 0 0 3.36585 0 7.5ZM3.67683 7.13415C4.0061 6.80488 4.53659 6.80488 4.84756 7.13415L5.92683 8.21342C6.07317 8.35976 6.32927 8.35976 6.47561 8.21342L10.1524 4.53659C10.4817 4.20732 11.0122 4.20732 11.3232 4.53659C11.6524 4.86585 11.6524 5.39634 11.3232 5.70732L6.78659 10.2439C6.45732 10.5732 5.92683 10.5732 5.61585 10.2439L3.67683 8.30488C3.34756 7.97561 3.34756 7.46341 3.67683 7.13415Z"
      fill="#33C25B"
    />
  </svg>
</template>
