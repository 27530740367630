<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="currentColor"
  >
    <path
      d="M6.40398 7.22656C6.05036 7.22656 5.76367 7.51325 5.76367 7.86688V13.1176C5.76367 13.4712 6.05036 13.7579 6.40398 13.7579C6.75761 13.7579 7.0443 13.4712 7.0443 13.1176V7.86706C7.0443 7.51325 6.75761 7.22656 6.40398 7.22656Z"
      fill="currentColor"
    />
    <path
      d="M9.60516 7.22656C9.25153 7.22656 8.96484 7.51325 8.96484 7.86688V13.1176C8.96484 13.4712 9.25153 13.7579 9.60516 13.7579C9.95878 13.7579 10.2455 13.4712 10.2455 13.1176V7.86706C10.2455 7.51325 9.95878 7.22656 9.60516 7.22656Z"
      fill="currentColor"
    />
    <path
      d="M14.4782 15.0354V4.281H15.1119C15.4656 4.281 15.7522 3.99431 15.7522 3.64069C15.7522 3.28706 15.4656 3.00038 15.1119 3.00038H11.8523V1.89525C11.8523 0.850125 11.0021 0 9.95719 0H5.94169C4.89675 0 4.04663 0.850125 4.04663 1.89506V3.00019H0.640313C0.286688 3.00019 0 3.28688 0 3.6405C0 3.99413 0.286688 4.28081 0.640313 4.28081H1.42069V15.0354C1.42069 16.6727 2.74781 18 4.38525 18H11.5136C13.1511 18 14.4782 16.6727 14.4782 15.0354ZM5.32725 1.89506C5.32725 1.55625 5.60287 1.28062 5.94169 1.28062H9.957C10.2958 1.28062 10.5714 1.55625 10.5714 1.89506V3.00019H5.32725V1.89506ZM2.70131 15.0354V4.3815H13.1976V15.0354C13.1976 15.9639 12.4423 16.7194 11.5138 16.7194H4.38525C3.45675 16.7194 2.70131 15.9639 2.70131 15.0354Z"
      fill="currentColor"
    />
  </svg>
</template>
