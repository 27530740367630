import api from '~utils/api';
import { userRoles } from '~utils/globals';
import Schools from '~utils/services/Schools';
import Users from '~utils/services/Users';
import User from "../../utils/entities/User";

const getInitialState = () => ({
  user: null,
  role: null,
  school: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    isUserLoaded: state => state.user !== null,
    role: state => state.role,
    schoolId: state => state.user.school?._id,
    school: state => state.school,
    isSchoolNotLoaded: state => state.school === null,
    hasNotSchool: state => state.school === null,
    user: state => state.user,
    regionRespondered: state => state.user.regionRespondered,
    hasRespondedRegion: state => Boolean(state.user.regionRespondered),
    isResponder: state => state.user.role === userRoles.responderRegion,
    favoriteGrades: state => state.user.favoriteGrades ?? [],
    hasOs: state => Boolean(state.user.idOS),
    connectionEstablished: state => state.user.connectionEstablished,
  },
  mutations: {
    setUser: (state, data) => {
      Object.entries(data)
        .forEach(([key, value]) => {
          state[key] = value;
          return true;
        });
      state.user = data;
    },
    setRole: (state, role) => {
      state.role = role;
    },
    setSchool: (state, school) => {
      state.school = school;
    },
    setFavoriteGrades: (state, gradesId) => {
      state.user.favoriteGrades = gradesId;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    CHANGE_ROLE: async ({ commit, dispatch }, role) => {
      try {
        const response = await api.post('/api/user/change-role', { newRole: role });
        const newUserInfo = response.data.data;
        dispatch('resetAllState', null, { root: true });
        commit('setRole', role);
        commit('setSchool', newUserInfo.school);
        commit('setUser', new User(newUserInfo));
      } catch (error) {
        console.error(error);
      }
    },
    FETCH_USER_INFO: async ({ commit }) => {
      try {
        const user = await Users.fetchAuthorizedUser();
        commit('setUser', user);
      } catch (e) {
        console.error(e.response);
      }
    },
    fetchSchool: async ({ getters, commit }) => {
      if (getters.schoolId) {
        const userSchool = await Schools.fetchOne(getters.schoolId);
        commit('setSchool', userSchool);
      }
    },
  },
};
