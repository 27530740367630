<template>
  <div class="empty-layout">
    <div class="empty-layout__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>

<style lang="scss">
.empty-layout {
  display: flex;
  flex-direction: column;
  &__content {
    flex: 1;
  }
}
</style>
