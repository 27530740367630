<template>
  <div
    class="base-select"
    :class="posLabelClass"
  >
    <label
      v-if="label || label.title"
      class="base-select__label"
    >{{
      typeof label === 'string' ? label : label.title
    }}</label>
    <div class="base-select__body">
      <vSelect
        v-model="value"
        :disabled="disable"
        :loading="loading"
        :options="options"
        :reduce="(val) =&gt; (val.value ? val.value : val)"
        :searchable="false"
        @option:selected="selected"
      >
        <template #open-indicator>
          <div class="base-select__open" />
        </template>
        <template #no-options>
          Нет значений
        </template>
      </vSelect>
      <div
        v-if="compErrors"
        class="base-select__errors"
      >
        <div
          v-for="error in compErrors"
          :key="error"
          class="base-select__error"
        >
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import vSelect from '~node_modules/vue-select';
import '~node_modules/vue-select/dist/vue-select.css';

export default {
  name: 'BaseSelect',
  components: {
    vSelect,
  },
  props: {
    modelValue: {
      type: [Object, String],
      default() {
        return '';
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    disable: {
      type: Boolean,
      default() {
        return null;
      },
    },
    selected: {
      type: Function,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errors: {
      type: Array,
      default: () => [],
    },
    label: {
      type: [Object, String],
      default() {
        return {
          title: null,
          position: null,
        };
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const posLabelClass = computed(() => {
      if (typeof props.label === 'string') {
        return 'base-select--top';
      }
      let className = 'base-select--';
      switch (props.label.position) {
        case 'left':
          className += 'left';
          break;
        default:
          className = null;
          break;
      }
      return className;
    });
    const compErrors = computed(() => {
      const { errors } = props;
      const arr = [];

      if (errors && errors.length) {
        errors.forEach(item => {
          if (item) {
            arr.push(item);
          }
        });
      }

      return arr.length ? arr : null;
    });

    return {
      value,
      posLabelClass,
      compErrors,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-select {
  :deep(.v-select) {
    .vs {
      &__selected-options {
        padding-left: 0;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        margin-right: 5px;
      }
      &__search {
        height: 0;
      }
      &__clear {
        display: none;
      }
      &__dropdown-toggle {
        padding: 10px 15px;
        border-color: #efeef1;
        border-radius: 8px;
        min-height: 51px;
      }
      &__actions {
        padding-right: 25px;
      }
      &__selected,
      &__dropdown-option {
        padding-left: 0;
        margin-left: 0;
        font-size: 15px;
        color: #001842;
      }
      &__dropdown-option {
        padding-left: 15px;
      }
      &__dropdown-option--selected {
        font-size: 17px;
        color: #001842;
      }
      &__dropdown-menu {
        border-color: #efeef1;
      }
    }
  }
  :deep(.v-select.vs--open) {
    .base-select__open {
      transform: rotate(180deg);
    }
    .vs__dropdown-toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  :deep(.v-select.vs--loading) {
    .base-select__open {
      display: none;
    }
  }
  &--left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .base-select {
      &__label {
        margin-right: 10px;
      }
    }
  }
  &__label {
    display: block;
    font-size: 16px;
  }
  &__body {
    min-width: 160px;
  }
  &__open {
    width: 10px;
    height: 6px;
    background: url('/front/vue/assets/icons/botArrow.svg') center center/auto
      no-repeat;
  }
  &__errors {
    margin-top: 24px;
    margin-bottom: 15px;
  }
  &__error {
    font-size: 15px;
    margin-bottom: 10px;
    color: red;
    font-weight: 400;
  }
}
</style>
