import api from '~utils/api';
import { apiEndpoints } from '~utils/globals';

export default class Locations {
  static async fetch() {
    const { data } = await api.get(apiEndpoints.locations);
    return data.data.docs;
  }

  static async fetchDistricts(regionId) {
    const { data } = await api.get(`${apiEndpoints.districts}?regionId=${regionId}`);
    return data.districts;
  }

  static async fetchCities(districtId) {
    const { data } = await api.get(`${apiEndpoints.cities}?districtId=${districtId}`);
    return data.cities;
  }

  static async createCity(name, districtId) {
    try {
      const { data } = await api.post(`${apiEndpoints.createCity}`, { cityName: name, districtId });
      return {
        city: data.data,
        created: data.success,
        message: data.message,
      };
    } catch (e) {
      return {
        city: {},
        cityName: e.response.data.data,
        created: false,
        message: e.response.data.message,
      };
    }

  }
}
