import { userRoles } from '~utils/globals';
import Users from '~utils/services/Users';

const getInitialState = () => ({
  responders: null,
  pagination: null,
  filterByName: null,
  isLoading: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    all: state => state.responders ?? [],
    page: state => state.pagination?.skip,
    total: state => state.pagination?.total,
    pagesCount: state => state.pagination?.totalPages,
    pagination: state => state.pagination,
    perPage: state => state.pagination?.limit,
    isNotLoaded: state => state.responders === null,
    isEmpty: state => state.responders?.length === 0,
    getById: state => id => state.responders.find(({ _id }) => id === _id),
    isFiltered: state => state.filterByName !== null,
    isLoading: state => state.isLoading,
  },
  mutations: {
    set: (state, { responders, pagination }) => {
      state.responders = responders;
      state.pagination = pagination;
    },
    add: (state, responder) => {
      state.responders.push(responder);
    },
    delete: (state, id) => {
      state.responders = state.responders.filter(({ _id }) => _id !== id);
    },
    update: (state, updatedResponder) => {
      const idx = state.responders.findIndex(({ _id }) => updatedResponder._id === _id);
      state.responders[idx] = updatedResponder;
    },
    setNameFilter: (state, searchName) => {
      if (!searchName) {
        state.filterByName = null;
      } else {
        state.filterByName = searchName;
      }
    },
    setLoading: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    fetch: async ({ commit }, { page, filterByName }) => {
      commit('setLoading', true);
      const { users, pagination } = await Users.fetch([userRoles.responderRegion], { page, fioContains: filterByName });
      commit('setNameFilter', filterByName);
      commit('set', { responders: users, pagination });
      commit('setLoading', false);
    },
    add: async ({ commit }, data) => {
      await Users.create({ data, role: userRoles.responderRegion });
      commit('add', data);
    },
    delete: async ({ commit }, id) => {
      await Users.remove(id);
      commit('delete', id);
    },
    update: async ({ commit }, updatedResponder) => {
      await Users.update(updatedResponder);
      commit('update', updatedResponder);
    },
  },
};
