<template>
  <div
    v-if="page &amp;&amp; lastPage"
    class="base-pagination"
    :class="`base-pagination--${siteVersion}`"
  >
    <div class="base-pagination__list">
      <div
        class="base-pagination__item base-pagination__arrow base-pagination__prev"
        @click="emitPage(page - 1)"
      >
        <div class="base-pagination__link">
          &lt;
        </div>
      </div>
      <div class="base-pagination-numbers">
        <div
          v-for="pageNum in paginateData"
          :key="pageNum"
          class="base-pagination__item base-pagination__number"
          :class="{ active: page === pageNum }"
          @click="emitPage(pageNum)"
        >
          <div class="base-pagination__link">
            {{ pageNum }}
          </div>
        </div>
      </div>
      <div
        class="base-pagination__item base-pagination__arrow base-pagination__next"
        @click="emitPage(page + 1)"
      >
        <div class="base-pagination__link">
          &gt;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BasePagination',
  props: {
    page: {
      type: Number,
      default: null,
    },
    lastPage: {
      type: Number,
      default: null,
    },
  },
  emits: ['changePage'],
  setup(props, { emit }) {
    const { settings } = useStore().state;
    const siteVersion = computed(() => settings.siteVersion);
    function pagination(c, m) {
      const current = c;
      const last = m;
      const delta = 2;
      const left = current - delta;
      const right = current + delta + 1;
      const range = [];
      const rangeWithDots = [];
      let l;

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    }

    const paginateData = computed(() => pagination(props.page, props.lastPage));

    function emitPage(pageNum) {
      if (pageNum === props.page) {
        return false;
      }

      let page = pageNum;
      if (pageNum === 0) {
        page = props.lastPage;
      } else if (pageNum === props.lastPage + 1) {
        page = 1;
      }

      emit('changePage', page);

      return true;
    }

    return {
      paginateData,
      emitPage,
      siteVersion,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-pagination {
  margin: 25px 0;
  user-select: none;
  &__list {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    list-style-type: none;
  }

  &-numbers {
    align-items: center;
    display: flex;

    & > *:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__arrow,
  &__link {
    cursor: pointer;
  }

  &__arrow {
    display: grid;
    height: 100%;
    place-items: center;
    width: 40px;

    .base-pagination__link {
      color: $pink;
      font-size: 14px;
      line-height: 90%;
      margin: 0;
      padding: 0;
    }
  }

  &__number {
    border-radius: 50%;
    cursor: pointer;
    line-height: 0;

    &.active {
      color: inherit;
      border-width: 2px;

      .base-pagination__link {
        color: inherit;
        font-size: 21.551px;
        margin: 0 13px;
      }
    }
  }

  &__link {
    color: inherit;
    font-size: 10.3158px;
    font-weight: 600;
    margin: 0 8px;
    padding: 50% 0;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 575px) {
    &__number {
      &.active {
        .base-pagination__link {
          font-size: 14px;
          margin: 0 12px;
        }
      }
    }
  }
}

#pisa {
  .base-pagination {
    &__number {
      border: 1px solid $pisa-purple;
      color: $pisa-purple;
    }

    &__number.active {
      border: 1px solid $pink;
      color: $pink;
    }
  }
}

#almaty {
  .base-pagination {
    &__number {
      border: 1px solid $almaty-green;
      color: $almaty-green;
    }
    &__number.active {
      color: $pink;
      border-color: $pink;
    }
  }
}

#erg {
  .base-pagination {
    &__number {
      color: $erg-blue;
      border: 1px solid $erg-blue;
    }
    &__number.active {
      color: $pink;
      border-color: $pink;
    }
  }
}

#sultan {
  .base-pagination {
    &__number {
      color: #fff;
      border: 1px solid #fff;
    }
    &__number.active {
      color: $pink;
      border-color: $pink;
    }
  }
}
</style>
