<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.374 7.53333L12.3406 7.18333L10.7656 3.475C10.4823 2.8 9.51563 2.8 9.2323 3.475L7.6573 7.19167L3.6323 7.53333C2.89896 7.59167 2.59896 8.50833 3.1573 8.99167L6.21563 11.6417L5.29896 15.575C5.1323 16.2917 5.9073 16.8583 6.54063 16.475L9.99896 14.3917L13.4573 16.4833C14.0906 16.8667 14.8656 16.3 14.699 15.5833L13.7823 11.6417L16.8406 8.99167C17.399 8.50833 17.1073 7.59167 16.374 7.53333V7.53333ZM9.99928 12.8334L6.86595 14.7251L7.69928 11.1584L4.93262 8.75841L8.58262 8.44175L9.99928 5.08341L11.4243 8.45008L15.0743 8.76675L12.3076 11.1667L13.141 14.7334L9.99928 12.8334Z"
      fill="#B8B3C1"
    />
  </svg>
</template>
