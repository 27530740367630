<template>
  <div class="base-spinner">
    <div
      class="base-spinner__circle-container"
      :style="{ width: size, height: size }"
    >
      <div class="base-spinner__circle">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    size: {
      type: String,
      default: '42px',
    },
  },
};
</script>

<style lang="scss">

.base-spinner {
  display: grid;
  place-items: center;
  background: transparent;
  height: 100%;
}

.base-spinner__circle-container {
  display: inline-block;
  position: relative;
}

.base-spinner__circle div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80%;
  height: 80%;
  border: 3px solid $pink;
  border-radius: 50%;
  animation: base-spinner-rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $pink transparent transparent transparent;
}

.base-spinner__circle div:nth-child(1) {
  animation-delay: -0.45s;
}
.base-spinner__circle div:nth-child(2) {
  animation-delay: -0.3s;
}
.base-spinner__circle div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes base-spinner-rotation {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
