<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49902 0.5C7.94674 0.5 7.49902 0.947715 7.49902 1.5V7.56445H1.5C0.947715 7.56445 0.5 8.01217 0.5 8.56445C0.5 9.11674 0.947715 9.56445 1.5 9.56445H7.49902V15.5C7.49902 16.0523 7.94674 16.5 8.49902 16.5C9.05131 16.5 9.49902 16.0523 9.49902 15.5V9.56445H15.5C16.0523 9.56445 16.5 9.11674 16.5 8.56445C16.5 8.01217 16.0523 7.56445 15.5 7.56445H9.49902V1.5C9.49902 0.947715 9.05131 0.5 8.49902 0.5Z"
      fill="currentColor"
    />
  </svg>
</template>
