import axios from 'axios';
import config from '../../../../../config';

/**
 * @version 1.1.0
 */
const AuthOS = async () => {
  let { search } = document.location;
  /* eslint-disable no-console */
  search = decodeURI(search);
  // ! not used
  if (search.includes('token')) {
    const token = search.split('?')[1].replace('token=', '');
    axios.post(`${window.location.origin}/login-by-token`, {
      token,
    }).then(data => {
      if (data.data.result === 'OK') {
        document.location.href = `${config.APP_URL}/profile/view`;
      }
    });
  }

  if (search.includes('code')) {
    const spl = search.split('&');

    const code = spl[0].split('=');

    const redirect = spl[1].split('=');
    axios.post(`${window.location.origin}/login-w-code`, {
      code: code[1],
      // osLoginDomain: config[window.location.origin].APP_URL_LOGIN_OS,
      // osDomain: config[window.location.origin].APP_URL_OS,
    }).then(() => {
    }).finally(() => {
      document.location.href = `${redirect[1]}/diary`;
    });
  }

  if (search.includes('timeCodeAuth')) {
    const spl = search.split('&');

    const timeCodeAuth = spl[0].split('=');

    const redirect = spl[1].split('=');

    axios.post(`${window.location.origin}/login`, {
      timeCodeAuth: timeCodeAuth[1],
      osLoginDomain: config[window.location.origin].APP_URL_LOGIN_OS,
      osDomain: config[window.location.origin].APP_URL_OS,
    }).then(() => {
    }).finally(() => {
      document.location.href = `${config.APP_URL}/profile/view`;
    });
  }
};

export default AuthOS;
