<template>
  <footer
    class="base-footer"
    :class="classes"
  >
    <div class="base-footer__inner container">
      <div class="base-footer__copyright">
        <span class="me-12">
          {{ tradeMark }}
        </span>
        <a
          v-if="false"
          class="base-footer__link"
          href="mailto:karnurmax@mail.ru"
        >Техническая поддержка karnurmax@mail.ru</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { t } = useI18n();
    const { siteVersion } = useStore().state.settings;
    const licenses = {
      almaty: `© 2022, «Almaty Sifrlyq Mektebi». ${t('Все права защищены.')}`,
      erg: `© 2022, «ERG Digital mektebi.». ${t('Все права защищены.')}`,
      pisa: `© 2023, ООО «Открытая школа» ${t('Все права защищены.')}`,
      sultan: `© 2023, ТОО «Amansultan» ${t('Все права защищены.')}`,
    };
    const tradeMark = licenses[siteVersion];
    return {
      tradeMark,
    };
  },
};
</script>

<style lang="scss" scoped>

.base-footer {
  bottom: 0;
  padding: 34px 40px;

  &__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__copyright {
    font-family: $ibm-plex-sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 23 / 18;
  }

  &__link {
    font-size: 18px;
    transition: 0.3s;
    font-family: $ibm-plex-sans;
    font-weight: 500;
    line-height: 23 / 18;

    transition: color 0.25s;
  }
}

#pisa {
  .base-footer {
    background: $grey;

    &__link {
      &:hover {
        color: $pisa-purple;
      }
    }
  }
}

#almaty {
  .base-footer {
    color: #fff;
    background: $almaty-blue;

    &__link {
      &:hover {
        color: $almaty-green;
      }
    }
  }
}

#erg {
  .base-footer {
    color: #fff;
    background: $erg-green;

    &__link {
      &:hover {
        color: $erg-blue;
      }
    }
  }
}

#sultan {
  .base-footer {
    background: $sultan-blue-3;

    &__link {
      &:hover {
        color: $sultan-blue;
      }
    }
  }
}
</style>
