import api2 from '@/services/api2';

export default {
  namespaced: true,
  state: {
    teachers: [],
  },
  getters: {
    teachers: state => state.teachers,
  },
  mutations: {
    setTeachers(state, values) {
      state.teachers = values;
    },
  },
  actions: {
    async parseTeachersFromXlsx({ commit }, xls) {
      const formData = new FormData();
      formData.append('file', xls);
      const { data } = await api2.post('/teachers/excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      commit('setTeachers', data);
      return data;
    },
    async createTeachers(empty, { teachers }) {
      const { data } = await api2.post('/teachers', teachers);
      return data;
    },
  },
};
