<template>
  <svg
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1644 6.7526C19.5772 7.11952 19.6143 7.75159 19.2474 8.16438L11.2474 17.1644C11.0584 17.3771 10.7877 17.4991 10.5031 17.5C10.2185 17.5009 9.94708 17.3805 9.75672 17.169L5.25672 12.169C4.88726 11.7585 4.92054 11.1262 5.33105 10.7567C5.74156 10.3873 6.37385 10.4205 6.7433 10.831L10.4954 15L17.7526 6.83565C18.1195 6.42287 18.7516 6.38569 19.1644 6.7526Z"
      fill="white"
    />
  </svg>
</template>
