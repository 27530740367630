<template>
  <div class="auth-layout">
    <div class="auth-layout__aside">
      <div class="auth-layout__intro">
        <div class="auth-layout__logo">
          <img
            :src="require(`~assets/${cardContent.img}`)"
            alt=""
          >
        </div>
        <div class="auth-layout__intro-title">
          {{ cardContent.text }}
        </div>
      </div>
    </div>
    <div class="auth-layout__content">
      <div class="auth-layout__content-wrapper">
        <router-view />
        <!-- <router-view v-slot="{ Component }">
          <transition
            name="slide-right"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AuthLayout',
  setup() {
    const { state } = useStore();
    const siteVersion = computed(() => state.settings.siteVersion);
    const { t } = useI18n();
    const cardContent = computed(() => {
      const contents = {
        pisa: {
          img: 'login/PISA-logo.svg',
          text: t(
            'Международная программа по оценке образовательных достижений учащихся.',
          ),
        },
        almaty: {
          img: 'login/almaty-logo.svg',
          text: t('Тренажеры по развитию функциональной грамотности.'),
        },
        erg: {
          img: 'login/ERG-logo.svg',
          text: t('Тренажеры по развитию функциональной грамотности.'),
        },
        sultan: {
          img: 'login/sultan-logo.png',
          text: t('Тренажеры по развитию функциональной грамотности.'),
        },
      };
      return contents[siteVersion.value];
    });
    // Берется значение из плагина, тестово изменяю для отображения
    return {
      cardContent,
      siteVersion,
    };
  },
};
</script>

<style lang="scss" scoped>

.auth-layout {
  background: $default-bg-color url('~@/assets/bg-pisa-purple.svg') center top;
  background-size: cover;
  display: flex;
  min-height: 100vh;

  &__aside {
    align-items: center;
    background: #5e66e2;
    border-radius: 0 40px 40px 0;
    display: flex;
    flex: 0 0 45%;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &::before {
      background: #5811f0;
      border-radius: 50%;
      content: '';

      height: 45vw;
      left: 0;
      position: absolute;

      top: 0;
      transform: translate(-55%, -43%);

      width: 45vw;
    }

    &::after {
      border: 120px solid $pink;
      border-radius: 50%;
      bottom: 0;

      content: '';
      height: 25vw;
      position: absolute;
      right: 0;

      transform: translate(55%, 43%);

      width: 25vw;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex: 0 0 55%;
    padding: 100px 30px 30px 150px;
  }

  &__content-wrapper {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
  }

  &__login-form {
    margin: auto;
    max-width: 540px;
  }

  &__intro {
    backdrop-filter: blur(40px);

    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(75, 14, 205, 0.1);
    display: flex;
    flex-direction: column;
    height: 440px;
    justify-content: space-between;
    max-height: 90%;
    max-width: 90%;

    padding: 64px 20px 50px 20px;
    width: 375px;
    z-index: 1;

    &-title {
      color: #fff;
      font-size: 28px;
      line-height: 36px;
    }
  }
  &__logo {
    margin-bottom: 20px;

    max-width: 100%;
    position: relative;
    width: 160px;

    img {
      width: 100%;
    }

    &::before {
      background: #fff;
      bottom: -20px;
      content: '';

      height: 2px;
      left: 0;

      position: absolute;

      width: 128px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;

    &__intro {
      border-radius: 0;
      height: 131px;
      padding: 0;
      width: 0;

      &-title {
        display: none;
      }
    }

    &__logo {
      left: 50%;
      margin-bottom: 0;
      max-width: unset;

      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      width: 320px;

      img {
        width: 100%;
      }

      &::before {
        content: none;
      }
    }

    &__aside {
      border-radius: 0 0 20px 20px;
      flex: 0 0 262px;

      &::before {
        content: '';
        height: 45vw;

        transform: translate(-57%, -38%);

        width: 45vw;
      }

      &::after {
        border: 60px solid #ac86ff;

        transform: translate(55%, 45%);
      }
    }

    &__content {
      display: flex;
      flex: 1;
      padding: 48px 24px 24px 24px;
    }

    &__login-form {
      margin: unset;
      width: 100%;
    }
  }
}

#erg {
  .auth-layout {
    background: white;

    &__logo {
      width: 297px;
    }
    &__aside {
      background: #00b6d2;
      &:before {
        width: 600px;
        height: 600px;
        top: 0;
        left: 0;
        transform: translate(-210px, -170px);
        background: url('~@/assets/login/ERG-top.svg') center center/contain
          no-repeat;
      }
      &:after {
        border: none;
        border-radius: 0;
        transform: translate(150px, 5px);
        background: url('~@/assets/login/ERG-bot.svg') center center/contain
          no-repeat;
      }
    }
  }
}
#almaty {
  .auth-layout {
    background: white;

    .auth-layout {
      &__logo {
        width: 297px;
      }
      &__aside {
        background: #3c4280;
        &:before {
          width: 230px;
          height: 230px;
          left: auto;
          right: 0;
          top: 0;
          background: url('~@/assets/login/almaty-circle.svg') center
            center/auto no-repeat;
          transform: translate(60px, -60px);
        }
        &:after {
          border: none;
          border-radius: 0;
          width: 147%;
          height: 90%;
          transform: translate(-2px, 57px);
          background: url('~@/assets/login/almaty-bg.png') center center/contain
            no-repeat;
        }
      }
    }
  }
}
#sultan {
  .auth-layout {
    background: white;

    &__logo {
      width: 297px;
    }
    &__aside {
      background: $sultan-blue;
      &:before {
        width: 600px;
        height: 600px;
        top: 0;
        left: 0;
        transform: translate(-210px, -170px);
        background: url('~@/assets/login/SULTAN-gears.svg') center
          center/contain no-repeat;
      }
      &:after {
        border: none;
        border-radius: 0;
        transform: translate(150px, 5px);
        background: url('~@/assets/login/SULTAN-clock.svg') center
          center/contain no-repeat;
      }
    }
  }
}
</style>
