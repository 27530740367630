<template>
  <div v-if="visible">
    <TheThemeChanger v-if="dev"/>
    <component :is="layout"/>
    <ModalContainer/>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useRoute} from 'vue-router';
import {container} from 'jenesius-vue-modal';
import TheThemeChanger from '~components/TheThemeChanger';
import AuthLayout from '~layout/AuthLayout';
import BaseLayout from '~layout/BaseLayout';
import EmptyLayout from '~layout/EmptyLayout';
import ExerciseLayout from '~layout/ExerciseLayout';
import AuthOS from './utils/helpers/auth/AuthOs';
import Base2Layout from '~layout/Base2Layout';

export default {
  name: "App",
  components: {
    ModalContainer: container,
    AuthLayout,
    BaseLayout,
    Base2Layout,
    EmptyLayout,
    ExerciseLayout,
    TheThemeChanger,
  },
  setup() {
    const visible = !document.location.search.includes("timeCodeAuth");
    const layout = computed(() => useRoute().meta.layout || "BaseLayout");
    const {host} = window.location;
    const dev = host === "localhost:5005";
    return {
      layout,
      dev,
      visible,
    };
  },
};
// сквозная авторизация со школой
AuthOS();
</script>
