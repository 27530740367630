<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7727 0.499512C5.99818 0.499512 0.5 5.99769 0.5 12.7722C0.5 19.5468 5.99818 25.045 12.7727 25.045C19.5473 25.045 25.0455 19.5468 25.0455 12.7722C25.0455 5.99769 19.5473 0.499512 12.7727 0.499512ZM17.2025 8.28005C18.5156 8.28005 19.5711 9.33551 19.5711 10.6487C19.5711 11.9619 18.5156 13.0173 17.2025 13.0173C15.8893 13.0173 14.8338 11.9619 14.8338 10.6487C14.8216 9.33551 15.8893 8.28005 17.2025 8.28005ZM9.83857 6.34113C11.434 6.34113 12.7349 7.64204 12.7349 9.23749C12.7349 10.8329 11.434 12.1339 9.83857 12.1339C8.24311 12.1339 6.94221 10.8329 6.94221 9.23749C6.94221 7.62977 8.23084 6.34113 9.83857 6.34113ZM9.83996 17.5454V22.1476C6.89451 21.2272 4.56269 18.9567 3.53178 16.0604C4.82042 14.6858 8.03587 13.9863 9.83996 13.9863C10.4904 13.9863 11.3127 14.0845 12.1718 14.2563C10.1591 15.324 9.83996 16.7354 9.83996 17.5454ZM12.7737 22.59C12.4423 22.59 12.1232 22.5777 11.8042 22.5409V17.5459C11.8042 15.8031 15.4123 14.9318 17.2042 14.9318C18.5173 14.9318 20.7878 15.4104 21.9169 16.3431C20.481 19.9881 16.9342 22.59 12.7737 22.59Z"
    />
  </svg>
</template>
