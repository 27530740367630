<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97666 6.45575C10.3733 6.45575 10.6947 6.35081 10.9315 6.14418C11.178 5.92949 11.3031 5.63902 11.3031 5.28061C11.3031 4.92254 11.1781 4.63188 10.9317 4.41719C10.6943 4.2101 10.3727 4.10547 9.9765 4.10547C9.602 4.10547 9.28718 4.20917 9.04055 4.41394C8.78137 4.62878 8.65039 4.92021 8.65039 5.2806C8.65039 5.64131 8.78168 5.93302 9.04055 6.14727C9.28686 6.35188 9.60198 6.45574 9.97666 6.45574L9.97666 6.45575Z"
    />
    <path
      d="M11.0171 14.6933V7.50879H7.86523V8.7095H8.9844V14.6936H7.86523V15.8943H12.1363V14.6936L11.0171 14.6933Z"
    />
    <path
      d="M10 1.11111C14.9012 1.11111 18.8889 5.09881 18.8889 10C18.8889 14.9012 14.9012 18.8889 10 18.8889C5.09881 18.8889 1.11111 14.9012 1.11111 10C1.11111 5.09881 5.09881 1.11111 10 1.11111ZM10 0C4.47698 0 0 4.47698 0 10C0 15.523 4.47698 20 10 20C15.523 20 20 15.523 20 10C20 4.47698 15.523 0 10 0Z"
    />
  </svg>
</template>
