<template>
  <header
    v-if="isUserLoaded"
    class="base-header"
  >
    <div class="base-header__sub-menu">
      <div class="base-header__sub-menu-container container">
        <img
          class="base-header__logo"
          :src="logoSrc"
          :alt="logoName"
        >
        <div class="base-header__sub-navs">
          <router-link
            v-for="(value, key) in currentNavs"
            :key="key"
            class="base-header__nav"
            :to="value"
          >
            {{ $t(`navs.${key}`) }}
          </router-link>
        </div>
        <div class="base-header__user">
          <TheHeaderMenu />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { navsMapping } from '~utils/globals';
import TheHeaderMenu from '~components/TheHeaderMenu';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderMenu,
  },
  setup() {
    const { getters } = useStore();

    const role = computed(() => getters['user/role']);
    const currentNavs = computed(() => navsMapping[role.value]);
    const isUserLoaded = computed(() => getters['user/isUserLoaded']);

    const logoName = computed(() => getters['settings/siteVersion']);

    // eslint-disable-next-line global-require,import/no-dynamic-require
    const logoSrc = computed(() => require(`~assets/logos/${logoName.value}.svg`));
    return {
      role,
      currentNavs,
      isUserLoaded,
      logoSrc,
      logoName,
    };
  },
};
</script>

<style lang="scss" scoped>

.base-header {
  background: $grey;
  font-weight: 500;

  &__main-menu {
    align-items: center;

    display: flex;
    padding: 8px 40px;
  }

  &__logo {
    margin-right: 90px;
    max-height: 55px;
    height: 55px;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__burger {
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
    margin-right: 0;
    outline: none;
    padding: 10px;
    position: relative;
  }

  &__navs {
    display: flex;
    flex: 1;
  }

  &__nav {
    cursor: pointer;
    font-size: 18px;
    line-height: 15px;
    text-decoration: none;
    transition: color 0.3s;

    &:not(:last-child) {
      margin-right: 50px;

      @media (max-width: $small-width) {
        margin-right: 30px;
      }
    }

    &-burger {
      font-size: 26px;
      font-weight: 600;
      line-height: 28px;
      padding-left: 40px !important;
      padding-top: 38px !important;
    }
  }

  &__sub-menu {
    display: flex;

    padding: 9px 0;
  }

  &__sub-menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__burger-wrapper {
    align-items: center;
    background: transparent;
    display: flex;
    justify-content: center;
    left: 0;
    min-height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 20;
  }

  &__navs-burger {
    align-items: flex-start;
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    left: 0;
    padding-bottom: 40px;
    position: absolute;
    top: 64px;
    width: 100vw;
    z-index: 21;
  }

  &__user {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}

#pisa {
  .base-header {
    background: $grey;
    font-weight: 500;

    &__nav {
      &:hover {
        color: $pisa-purple;
      }

      &.router-link-active {
        color: $pisa-purple;
      }
    }

    &__sub-menu {
      background: $grey;
    }
  }
}

#almaty {
  .base-header {
    background: $almaty-blue;
    font-weight: 500;
    color: #fff;
    &__nav {
      &:hover {
        color: $almaty-green;
      }

      &.router-link-active {
        color: $almaty-green;
      }
    }

    &__sub-menu {
      background: $almaty-blue;
      box-shadow: -1px 17px 39px -24px #141731 inset;
    }
  }
}

#erg {
  .base-header {
    background: $erg-green;
    font-weight: 500;
    color: #fff;

    &__nav {
      &:hover {
        color: $erg-blue;
      }

      &.router-link-active {
        color: $erg-blue;
      }
    }

    &__sub-menu {
      background: $erg-green;
      box-shadow: -1px 17px 39px -24px #02535f inset;
    }
  }
}

#sultan {
  .base-header {
    background: $sultan-white;
    font-weight: 500;

    &__nav {
      &:hover {
        color: $sultan-blue;
      }

      &.router-link-active {
        color: $sultan-blue;
      }
    }

    &__sub-menu {
      position: relative;
      background: $sultan-blue-3;
      box-shadow: 0px 17px 40px -40px black inset;
    }
  }
}
</style>

<style lang="scss">

.base-header__control {
  .multiselect {
    background: #fff;
    border-radius: 8px;
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 37px;
    line-height: 21px;
    min-width: 179px;
    padding: 8px 8px 8px 16px;
    position: relative;

    &--active {
      outline: none;

      .multiselect__select {
        transform: rotate(180deg);
      }
    }

    &__tags {
      padding: 0 15px 0 0;
    }

    &__content-wrapper {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 1px 5px rgb(0 0 0 / 24%);
      left: 0;
      margin-top: 5px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1000;
    }

    &__content {
      list-style: none;
      width: 100%;
    }

    &__element {
      cursor: pointer;

      &:hover {
        background: #efeef1;
      }

      span {
        display: inline-block;
        padding: 5px 8px;
        width: 100%;
      }
    }

    &__option {
      min-height: 0;
      white-space: normal;
    }

    &__select {
      background: url('~@/assets/icons/botArrow.svg') no-repeat;
      border-color: transparent;
      border-width: 10px;
      color: $black;
      content: '';
      height: 10px;
      margin: 0;
      position: absolute;
      right: 10px;
      top: 45%;
      width: 10px;
    }
  }
}
</style>
