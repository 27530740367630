<template>
  <transition name="toast" appear>
    <div v-show="isActive" ref="toast" class="toast" :class="{ toast_error: error }">
      <div class="toast__icon toast__info">
        <BaseIcon v-if="!error" icon-name="IconCheck" :size="20" />
        <BaseIcon v-else  icon-name="IconExclamationMark" :size="20" />
      </div>
      <p>{{ message }}</p>
      <button v-if="showCloseBtn" class="toast__icon toast__close" @click="dismiss">
        <BaseIcon icon-name="IconClose" :size="20" />
      </button>
    </div>
  </transition>
</template>

<script>
import { render } from 'vue';
import { removeElement } from '@/plugins/toast/helpers.js';
import BaseIcon from '@/components/Base/BaseIcon.vue';

export default {
  name: 'BaseToast',
  components: {
    BaseIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
      default: 'This is Toast',
    },
    error: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      toastsContainer: null,
    };
  },
  beforeMount() {
    this.$nextTick(() => {
      this.setupContainer();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.handleWrapper();
    });
  },
  methods: {
    setupContainer() {
      this.toastsContainer = document.querySelector('.toasts-container');
      if (this.toastsContainer) return;

      this.toastsContainer = document.createElement('div');
      this.toastsContainer.className = 'toasts-container';
      const toastsWrapper = document.createElement('div');
      toastsWrapper.className = 'toasts-wrapper';
      toastsWrapper.appendChild(this.toastsContainer);

      const container = document.body;
      container.appendChild(toastsWrapper);
    },
    handleWrapper() {
      const wrapper = this.$refs.toast.parentElement;
      this.toastsContainer.insertAdjacentElement('afterbegin', this.$refs.toast);
      removeElement(wrapper);
      this.isActive = true;
    },
    dismiss() {
      this.isActive = false;
      setTimeout(() => {
        const toast = this.$refs.toast;
        render(null, toast);
        removeElement(toast);
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  background-color: #060e1f;
  color: #ffffff;
  border-radius: 0.5rem;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;

  &_error {
    background-color: #fc0d54;
  }

  &_error &__info {
    background-color: #ffffff;

    svg {
      fill: #fc0d54;
    }
  }

  &_error &__close {
    svg {
      fill: #ffffff;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    padding: 3px;
    width: 26px;
    height: 26px;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    padding: 3px;
    width: 26px;
    height: 26px;
    background: #27c25b;

    svg {
      fill: #ffffff;
    }
  }

  &__close {
    svg {
      fill: #999ead;
    }
  }
}

.toast-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.toast-enter-active {
  transition: transform 150ms, opacity 150ms;
}

.toast-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.toast-leave-active {
  transition: transform 150ms, opacity 150ms;
}

</style>

<style lang="scss">
.toasts-wrapper {
  position: fixed;
  bottom: 50px;
  z-index: 999;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    width: 100%;
    bottom: 12px;
  }
}

.toasts-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
