const apiEndpoints = {
  user: '/api/user',
  schools: '/api/school',
  grades: '/api/grade',
  articles: '/api/getArticles',
  locations: '/api/locations',
  cities: '/api/region/getCities',
  districts: '/api/region/getDistricts',
  createCity: '/api/cities/',
  favoriteGrade: '/api/favorite',
  verifyResetPassToken: '/api/verify-link',
  sendResetPasswordMail: '/api/send-email',
  changeUserPassword: '/api/reset-password',
  usersWithoutPagination: '/api/userList',
  statistics: {
    schoolsXls: '/statistic/schools/download',
    gradesXls: '/statistic/grades/download',
    schoolTeachersXlsx: '/statistic/teachers/school/download',
    activity: '/statistic/active-user-school',
    activitySchool: '/statistic/active-all-user',
    activityCompletedTasks: '/api/statistic/active-types-fl',
    generalSchoolsActivity: '/api/statistic/school-activity',
    effectiveness: '/statistic/studentsEffectiveness',
    countExercises: '/api/statistic/amount-exercises',
    schoolsGradeXlsx: '/statistic/school/download',
    teacherXLSX: '/statistic/teacher/download',
    districtXlsx: '/statistic/district/download',
    regionXlsx: '/statistic/region/download',
  },
};

const userRoles = {
  responderRegion: 'RESPONDER_REGION',
  responderSchool: 'RESPONDER_SCHOOL',
  admin: 'ADMIN',
  teacher: 'TEACHER',
  student: 'STUDENT',
};

const roleNames = {
  TEACHER: 'Учитель',
  STUDENT: 'Ученик',
  ADMIN: 'Главный администратор',
  RESPONDER_SCHOOL: 'Администратор школы',
  RESPONDER_REGION: 'Ответственный за регион',
};

const themes = ['pisa', 'almaty', 'erg', 'sultan'];

const localStorageKeys = {
  theme: 'theme',
};

const tableHeaders = {
  teachers: [
    {
      title: 'ФИО учителя',
      field: 'fio',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'login',
      type: 'text',
    },
    {
      title: 'Предмет',
      field: 'articles',
      type: 'text',
    },
    {
      title: 'Действия',
      field: 'edit',
      type: 'text',
    },
  ],

  teachersWithSchools: [
    {
      title: 'ФИО учителя',
      field: 'fio',
      type: 'text',
    },
    {
      title: 'Школа',
      field: 'school',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'login',
      type: 'text',
    },
    {
      title: 'Предмет',
      field: 'articles',
      type: 'text',
    },
    {
      title: 'Действия',
      field: 'edit',
      type: 'text',
    },
  ],

  schools: [
    {
      title: 'Школа',
      field: 'name',
      type: 'text',
    },
    {
      title: 'Город / населенный пункт',
      field: 'city',
      type: 'text',
    },
    {
      title: 'Администратор школы',
      field: 'names',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'logins',
      type: 'text',
    },
    {
      title: 'Действия',
      field: 'edit',
      type: 'text',
    },
  ],

  regionResponders: [
    {
      title: 'Регион',
      field: 'regionRespondered',
      type: 'text',
    },
    {
      title: 'ФИО ответственного',
      field: 'fullName',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'login',
      type: 'text',
    },
    {
      title: 'Действия',
      field: 'actions',
      type: 'button-group',
    },
  ],

  studentsOfGrade: [
    {
      title: 'ФИО ученика',
      field: 'fio',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'email',
      type: 'text',
    },
    {
      title: 'Код',
      field: 'authCode',
      type: 'text',
    },
    {
      title: 'Редактирование / удаление',
      field: 'edit',
      type: 'text',
    },
  ],

  studentsOfGradeForRegionResponder: [
    {
      title: 'ФИО ученика',
      field: 'fio',
      type: 'text',
    },
    {
      title: 'Школа',
      field: 'school',
      type: 'text',
    },
    {
      title: 'Класс',
      field: 'grade',
      type: 'text',
    },
    {
      title: 'Почта',
      field: 'email',
      type: 'text',
    },
    {
      title: 'Код',
      field: 'authCode',
      type: 'text',
    },
    {
      title: 'Редактирование / удаление',
      field: 'edit',
      type: 'text',
    },
  ],
};

const regExp = {
  notRuSymbols: /^[^А-ЯЁ]*/gi,
  notRuKzSymbols: /^[^А-ЯЁӘҒҚҰҢ]*/gi,
  notNumbers: /[^0-9]/gi,
  numbers: /[0-9]/gi,
  ruSymbols: /[А-ЯЁа-яё]/i,
};

const cookieKeys = {
  locale: 'locale',
};

const siteLocales = {
  ru: 'ru',
  kz: 'kz',
};

const navsMapping = {
  TEACHER: {
    'Каталог заданий': '/exercises-catalog',
    'Мои классы': '/grades',
    'Мои работы': '/teacher-works',
  },
  STUDENT: {
    'Мои работы': '/student-tests',
  },
  ADMIN: {
    Ответственные: '/responders',
    'Школы и учителя': '/school-and-teachers',
    Помощь: '/help',
  },
  RESPONDER_SCHOOL: {
    Учителя: '/teachers',
    'Классы и ученики': '/grades-and-students',
    // Помощь: '/help',
  },
  RESPONDER_REGION: {
    'Школы и учителя': '/school-and-teachers',
    Ученики: '/students',
    // Статистика: '/statistics',
    Помощь: '/help',
  },
};

export {
  apiEndpoints,
  userRoles,
  roleNames,
  themes,
  localStorageKeys,
  tableHeaders,
  regExp,
  cookieKeys,
  siteLocales,
  navsMapping,
};
