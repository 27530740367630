import api from '~utils/api';
import config from '../../../../config';
import { apiEndpoints } from '~utils/globals';

export default class Schools {
  static sort(schools, type) {
    if (type === 'none') {
      return schools;
    }

    const sortPredicates = {
      schoolAsc: (a, b) => a.name?.localeCompare(b.name),
      schoolDes: (a, b) => b.name?.localeCompare(a.name),
      cityAsc: (a, b) => a.city?.name?.localeCompare(b.city?.name),
      cityDes: (a, b) => b.city?.name?.localeCompare(a.city?.name),
    };

    return schools.slice().sort(sortPredicates[type]);
  }

  static async fetch() {
    const response = await api.get(apiEndpoints.schools);
    return response.data.data;
  }

  static async fetchOne(id) {
    const response = await api.get(`${apiEndpoints.schools}/${id}`);
    return response.data;
  }

  static async fetchByRegion(regionId) {
    const all = await Schools.fetch();
    return all.filter(school => school.region._id === regionId);
  }

  static async create({ name, responderIds = [] }) {
    return api.post(apiEndpoints.schools, {
      name,
      responderIds,
    });
  }

  static async update({ data, id }) {
    const res = api.patch(`${apiEndpoints.schools}/${id}`, { ...data });
    return res;
  }

  static async delete(id) {
    await api.delete(`${apiEndpoints.schools}/${id}`);
  }

  static async syncGrades() {
    await api.post(`${window.location.origin}/updateschoolos`, {
      osDomain: config[window.location.origin].APP_URL_OS,
    });
  }

  static async fetchGradesOS(schoolId) {
    await Schools.syncGrades();
    const response = await api.get(`${apiEndpoints.schools}/${schoolId}`);
    const { school, grades } = response.data;
    return { school, grades };
  }

  static async fetchGrades(schoolId) {
    const response = await api.get(`${apiEndpoints.schools}/${schoolId}`);
    const { school, grades } = response.data;
    return { school, grades };
  }

  static getNames(schools) {
    return schools.map(school => ({ name: school.name, id: school._id }));
  }

  static getId(school) {
    return school._id;
  }
}
