import { formatBySurname } from '../helpers/userNameFormatters';

export default class User {
  constructor(data) {
    this.id = data._id || data.id;
    this.idOS = data.idOS;
    this.surname = data.surname;
    this.name = data.name;
    this.patronymic = data.patronymic;
    this.login = data.login;
    this.region = data.region;
    this.country = data.country;
    this.district = data.district;
    this.school = data.school;
    this.city = data.city;
    this.organization = data.organization;
    this.authCode = data.authCode;
    this.role = data.role;
    this.created = data.created;
    this.roles = data.roles;
    this.regionRespondered = data.regionRespondered;
    this.favoriteGrades = data.favoriteGrades;
    this.connectionEstablished = data.connectionEstablished;
  }

  get fio() {
    return formatBySurname(this.surname, this.name, this.patronymic);
  }

  get _id() {
    return this.id;
  }

  _omitFieldsByEmpty(fieldsForCheck) {
    return fieldsForCheck.filter(name => this[name] === null || this[name] === undefined || this[name] === '' || this[name] === false);
  }

  /**
   * Returns array message
   * @param {Array} fieldsForCheck array fields equels name in User
   * @returns array message
   */
  getEmptyFieldsWarningMessages(fieldsForCheck) {
    const messageMap = {
      school: 'К Вашему аккаунту не привязана школа! Во вкладке "Профиль" нажмите кнопку "Синхронизировать", либо перезайдите в аккаунт через основную платформу',
      connectionEstablished: 'Вы не имеете соединения с основной платформой, "Синхронизация" не доступна. Войдите в Тренажер ФГ через основную платформу',
      regionRespondered: 'Вы не прикреплены к региону. Во вкладке "Профиль" нажмите кнопку "Синхронизировать", либо перезайдите в Тренажер ФГ "Через ОШ"',
      schoolSultan: 'К Вашему аккаунту не привязана школа! Ответсвенный за регион или Главный Администратор может прикрепить Вас к школе',
      regionResponderedSultan: 'К Вашему аккаунту не привязан регион! Главный Администратор может прикрепить Вас к региону',
      idOS: 'Ваш аккаунт не привязан к школе',
    };
    return this._omitFieldsByEmpty(fieldsForCheck).map(name => ({ key: name, value: messageMap[name] }) || `Это поле пусто - ${name}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getFieldsInfoMessages(fieldsForCheck) {
    const messageMap = {
      infoResponderedRegion: 'Ответсвенный за регион не может добавлять школы с основной платформы. Чтобы добавить школу "Администратор школы" должен авторизоваться на платформе "Тренажер ФГ"',
    };
    return fieldsForCheck.map(name => ({ key: name, value: messageMap[name] }) || `Это поле пусто - ${name}`);
  }
}
