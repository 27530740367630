import { isString, capitalize } from 'lodash';

function formatBySurname(surname, name, patronymic) {
  const isInvalidNames = [surname, name].some(value => !isString(value));
  if (isInvalidNames) {
    return '';
  }

  const formattedSurname = `${capitalize(surname)} `;
  const formattedName = `${capitalize(name.charAt(0))}. `;
  const formattedPatronymic = patronymic ? `${capitalize(patronymic.charAt(0))}.` : '';

  return `${formattedSurname}${formattedName}${formattedPatronymic}`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  formatBySurname,
};
