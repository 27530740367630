<template>
  <svg
    width="18px"
    height="18px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
  >
    <path
      d="M18 16.3796L13.4602 11.8335C14.3598 10.6027 14.8967 9.08996 14.8967 7.44891C14.8967 3.33482 11.5624 0 7.44833 0C3.33482 0 0 3.33482 0 7.44891C0 11.563 3.33482 14.8978 7.44833 14.8978C9.0934 14.8978 10.6084 14.3586 11.8409 13.455C11.8409 13.455 15.3342 16.9552 16.379 18C17.0417 17.3446 17.3387 17.0428 18 16.3796ZM2.29197 7.44891C2.29197 4.60056 4.60056 2.29197 7.44833 2.29197C10.2967 2.29197 12.6053 4.60056 12.6053 7.44891C12.6053 10.2973 10.2967 12.6058 7.44833 12.6058C4.60056 12.6058 2.29197 10.2973 2.29197 7.44891Z"
    />
  </svg>
</template>
