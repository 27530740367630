<template>
  <div class="theme-changer">
    <button
      v-for="(theme, idx) in themes"
      :key="idx"
      class="theme"
      :class="{ currentTheme: siteVersion === theme }"
      @click="changeTheme(theme)"
    >
      {{ theme }}
    </button>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import cookie from 'vue-cookies';
import { themes } from '~utils/globals';

export default {
  name: 'TheThemeChanger',
  setup() {
    const { siteVersion } = useStore().state.settings;

    function changeTheme(value) {
      if (value === 'pisa') {
        cookie.set('locale', 'ru');
      }
      window.localStorage.setItem('siteVersion', value);
      window.location.reload();
    }

    return {
      siteVersion,
      changeTheme,
      themes,
    };
  },
};
</script>

<style lang="scss">

.theme-changer {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  right: 0;

  & > *:not(:last-child) {
    margin-bottom: 2px;
  }
  @media print {
    display: none;
  }
}

.theme {
  border: none;
  background: #1cc0b8;
  color: white;
  cursor: pointer;
  padding: 5px;
  &:hover {
    background: #04a093;
  }
}
.currentTheme {
  background: #04887d;
}
</style>
