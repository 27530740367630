<template>
  <svg
    :width="24"
    :height="24"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97425 12.6074C10.8098 12.6074 11.2528 12.104 11.283 11.2483L11.434 4.28188C11.4441 4.18121 11.4441 4.0604 11.4441 3.97987C11.4441 3.05369 10.8803 2.5 9.98432 2.5C9.07828 2.5 8.50445 3.05369 8.50445 3.97987C8.50445 4.0604 8.51452 4.18121 8.51452 4.28188L8.67559 11.2483C8.69573 12.104 9.13868 12.6074 9.97425 12.6074ZM9.97425 17.5C10.8904 17.5 11.6152 16.8054 11.6152 15.9195C11.6152 15.0336 10.8904 14.3389 9.97425 14.3389C9.05815 14.3389 8.33331 15.0336 8.33331 15.9195C8.33331 16.8054 9.05815 17.5 9.97425 17.5Z"
      fill="#f93c3c"
    />
  </svg>
</template>
