<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9999 13.0998V17.0917C17.9999 17.1712 17.9885 17.245 17.9716 17.3132C17.8922 17.705 17.5465 18.0003 17.1386 18.0003L0.855804 18.0001C0.419365 18.0001 0.0567662 17.665 0.0113856 17.2391C0 17.188 0 17.1427 0 17.0858V13.0939C0 12.6226 0.38537 12.2422 0.850126 12.2422C1.08243 12.2422 1.29782 12.3387 1.45088 12.492C1.60395 12.6454 1.70026 12.8612 1.70026 13.0939V16.2908H16.3054V13.0939C16.3054 12.6226 16.6908 12.2422 17.1556 12.2422C17.3879 12.2422 17.6033 12.3387 17.7563 12.492C17.9037 12.6513 18 12.8671 18 13.0998L17.9999 13.0998Z"
    />
    <path
      d="M13.9024 9.10209L9.72549 13.2869C9.7198 13.2983 9.70841 13.304 9.70288 13.3096C9.5498 13.463 9.3515 13.5594 9.15321 13.5878C9.13613 13.5878 9.11921 13.5935 9.10213 13.5935C9.06813 13.5992 9.03413 13.5992 9.00014 13.5992L8.90383 13.5935C8.88675 13.5935 8.86983 13.5878 8.85276 13.5878C8.64876 13.5594 8.456 13.463 8.30309 13.3096C8.2974 13.3039 8.28601 13.2925 8.28048 13.2869L4.10352 9.10209C3.91076 8.90896 3.81445 8.65355 3.81445 8.39804C3.81445 8.14248 3.91076 7.88691 4.10352 7.69398C4.48889 7.30788 5.11796 7.30788 5.50902 7.69398L8.01973 10.2094L8.01957 0.993687C8.01957 0.448538 8.46727 0 9.01139 0C9.28338 0 9.53276 0.113597 9.71412 0.289613C9.8955 0.471336 10.0032 0.715479 10.0032 0.993671V10.2094L12.5139 7.69396C12.8993 7.30786 13.5283 7.30786 13.9194 7.69396C14.2878 8.08577 14.2878 8.716 13.9025 9.10212L13.9024 9.10209Z"
    />
  </svg>
</template>
