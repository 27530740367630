import axios from 'axios';

const menus = {
  namespaced: true,
  state: {
    menu: null,
  },
  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
  },
  actions: {
    async fetchMenu({ commit }) {
      const { data } = await axios.get('/api/menu');
      commit('setMenu', data.menu);
    },
  },
};

export default menus;
