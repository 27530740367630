import cookie from 'vue-cookies';
import { setCookie } from '@/helpers/cookie';
import config from '../../../config';

function getCurrentRootDomain() {
  const splitCookie = window.location.hostname.split('.');

  if (splitCookie.length > 1) {
    return `${splitCookie[splitCookie.length - 2]}.${splitCookie[splitCookie.length - 1]}`;
  }

  return splitCookie[0];
}

function setInitialLocale() {
  let localeCookie = cookie.get('locale') || config.LOCALE;
  if (localeCookie === 'kk') localeCookie = 'kz';
  if (!localeCookie || localeCookie === 'undefined' || localeCookie === 'null') localeCookie = config.LOCALE;
  const domain = getCurrentRootDomain();
  setCookie('locale', localeCookie, { domain });
}

function downloadFile(url, fileName) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = fileName;

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });

  link.dispatchEvent(clickEvent);
}

function addIndexesInArrayWithObject(users) {
  return users.map((obj, idx) => {
    obj.idx = idx;
    return obj;
  });
}

function validateEmail(email) {
  const emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegExp.test(email);
}

function generateMongoId() {
  const ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));
  return ObjectId();
}

export {
  getCurrentRootDomain,
  setInitialLocale,
  downloadFile,
  addIndexesInArrayWithObject,
  validateEmail,
  generateMongoId
};
