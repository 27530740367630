<template>
  <div class="base-layout">
    <TheHeader />
    <div class="base-layout__content">
      <router-view v-slot="{ Component }">
        <transition
          name="slide-right"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '~components/TheHeader';
import TheFooter from '~components/TheFooter';

export default {
  name: 'BaseLayout',
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style lang="scss">

.base-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  &__content {
    flex: 1;
  }
}
</style>
