import api from '~utils/api';

const getInitialState = () => ({
  works: null,
  themes: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {},
  mutations: {
    SET_WORKS(state, works) {
      state.works = works;
    },
    SET_THEMES(state, themes) {
      state.themes = themes;
    },
    ADD_WORKS(state, works) {
      state.works = [...works, ...state.works];
    },
    ADD_THEMES(state, themes) {
      const ids = state.themes.map(theme => theme._id);
      const uniqThemes = themes.filter(theme => !ids.includes(theme._id));
      state.themes = [...state.themes, ...uniqThemes];
    },
    reset: state => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async FETCH_WORKS({ commit }) {
      try {
        const { data } = await (api.get('/api/work/list'));
        const { works, themes } = data;
        if (!works) return;
        commit('SET_WORKS', works);
        commit('SET_THEMES', themes);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
