<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 15.4"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.889.615c-2.357 2.729-4.714 5.427-7.071 8.156-.527.59-1.055 1.21-1.582 1.799l-.62.62c-.434-.496-1.24-1.054-1.55-1.396a66.915 66.915 0 0 1-1.955-2.14C1.53 5.856-1.075 8.492.475 10.29c1.365 1.551 2.822 3.412 4.528 4.56 1.86 1.24 3.194.216 4.435-1.241A944.628 944.628 0 0 0 18.525 3.25c1.55-1.799-1.055-4.466-2.636-2.636z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '12px',
    },
    height: {
      type: String,
      default: '10px',
    },
  },
};
</script>
