import api from '~utils/api';
import { apiEndpoints } from '~utils/globals';

export default class Articles {
  static async fetch() {
    const { data: schoolArticles } = await api.get(apiEndpoints.articles, {
      params: {
        type: 'school',
      },
    });
    const { data: primarySchoolArticles } = await api.get(apiEndpoints.articles, {
      params: {
        type: 'primarySchool',
      },
    });

    return {
      school: schoolArticles.map(article => ({ name: article.name, id: article._id })),
      primarySchool: primarySchoolArticles.map(article => ({ name: article.name, id: article._id })),
    };
  }

  static getIds(articles) {
    return articles.map(({ id }) => id);
  }

  static getNames(articles) {
    if (articles.length === 0) {
      return 'Предметов нет';
    }

    return articles.map(({ name }) => name).join(', ');
  }

  static getArticleById(id, articles) {
    if (!Array.isArray(articles)) {
      return undefined;
    }

    return articles.slice().find(article => article.id === id);
  }

  static getArticleByName(name, articles) {
    if (!Array.isArray(articles)) {
      return undefined;
    }

    return articles.slice().find(article => article.name === name);
  }

  static formatUserArticles(userArticles, allArticles) {
    return userArticles
      .map(name => Articles.getArticleByName(name, allArticles))
      .filter(article => article !== undefined);
  }

  static getArticlesByIds(ids, articles) {
    if (!ids) {
      return [];
    }

    return ids
      .map(id => Articles.getArticleById(id, articles))
      .filter(article => article !== undefined);
  }
}
