export default {
  namespaced: true,
  state: {
    allDates: [
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
    ],
    filterFG: [
      { _id: 'mathlit', title: 'Математическая грамотность' },
      { _id: 'creative', title: 'Креативное мышление' },
    ],
    selectedDates: [],
    selectedFG: [ { _id: 'mathlit', title: 'Математическая грамотность' } ],
    fullDateArr: [
      `${(new Date().getMonth() + 1) >= 9 ? `${new Date().getFullYear()}-09-01` : `${new Date().getFullYear() - 1}-09-01`}`,
      `${(new Date().getMonth() + 1) >= 9 ? `${new Date().getFullYear() + 1}-08-28` : `${new Date().getFullYear()}-08-28`}`
    ],
    region: null,
    regions: null,
    district: null,
    districts: null,
    school: null,
    schools: null,
    grade: null,
    grades: null,
    student: null,
    students: null,
  },
  getters: {
    allDates: state => state.allDates,
    selectedDates: state => state.selectedDates,
    filterFG: state => state.filterFG,
    selectedFG: state => state.selectedFG,
    fullDateArr: state => state.fullDateArr,
    region: state => state.region,
    regions: state => state.regions,
    district: state => state.district,
    districts: state => state.districts,
    school: state => state.school,
    schools: state => state.schools,
    grade: state => state.grade,
    grades: state => state.grades,
    student: state => state.student,
    students: state => state.students,
  },
  mutations: {
    selectedDates(state, value) {
      state.selectedDates = value;
    },
    setFullDateArr(state, value) {
      state.fullDateArr = value;
    },
    selectedFG(state, value) {
      state.selectedFG = value;
    },
    setActiveRegion(state, value) {
      state.region = value;
    },
    setRegions(state, value) {
      state.regions = value;
    },
    setActiveDistrict(state, value) {
      state.district = value;
    },
    setDistricts(state, value) {
      state.districts = value;
    },
    setActiveSchool(state, value) {
      state.school = value;
    },
    setSchools(state, value) {
      state.schools = value;
    },
    setActiveGrade(state, value) {
      state.grade = value;
    },
    setGrades(state, value) {
      state.grades = value;
    },
    setActiveStudent(state, value) {
      state.student = value;
    },
    setStudents(state, value) {
      state.students = value;
    },
  },
  actions: {
  },
};
