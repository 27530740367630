<template>
  <svg
    width="12px"
    height="12px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.341994 0.343552C-0.0485299 0.734077 -0.0485299 1.36724 0.341994 1.75777L4.63065 6.04642L0.388414 10.2887C-0.00211036 10.6792 -0.00211034 11.3123 0.388414 11.7029C0.778939 12.0934 1.4121 12.0934 1.80263 11.7029L6.04486 7.46063L10.2415 11.6573C10.632 12.0478 11.2652 12.0478 11.6557 11.6573C12.0462 11.2667 12.0462 10.6336 11.6557 10.243L7.45907 6.04642L11.7021 1.80337C12.0926 1.41285 12.0926 0.779683 11.7021 0.389159C11.3116 -0.00136552 10.6784 -0.00136586 10.2879 0.389159L6.04486 4.63221L1.75621 0.343552C1.36568 -0.0469718 0.732519 -0.0469721 0.341994 0.343552Z"
    />
  </svg>
</template>
