<template>
  <svg
    width="10px"
    height="20px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1952 9.07804C10.1616 9.36483 10.0317 9.63173 9.82678 9.83507L2.17936 17.4851C2.06037 17.6035 1.91924 17.6973 1.764 17.7612C1.60877 17.825 1.44248 17.8576 1.27464 17.8572C1.1068 17.8567 0.940692 17.8232 0.785805 17.7585C0.630919 17.6938 0.490294 17.5993 0.371943 17.4802C0.25359 17.3612 0.159823 17.22 0.0960274 17.0647C0.0322304 16.9094 -0.000346184 16.7431 0.000126839 16.5752C0.000598907 16.4073 0.0341187 16.2411 0.0987883 16.0862C0.163459 15.9312 0.257998 15.7906 0.377018 15.6722L7.11832 8.92861L0.377018 2.18502C0.257998 2.06664 0.163459 1.92597 0.0987883 1.77104C0.0341187 1.6161 0.000598907 1.44995 0.000126839 1.28205C-0.000346184 1.11415 0.0322304 0.947809 0.0960274 0.792517C0.159823 0.637222 0.25359 0.496019 0.371943 0.376972C0.490294 0.257925 0.630919 0.163363 0.785805 0.0986881C0.940692 0.0340137 1.1068 0.000494003 1.27464 4.00543e-05C1.44248 -0.000413895 1.60877 0.0322075 1.764 0.0960426C1.91924 0.15988 2.06037 0.253677 2.17936 0.372084L9.82678 8.02214C9.96436 8.15857 10.0691 8.32457 10.1329 8.50753C10.1967 8.69049 10.218 8.8856 10.1952 9.07804Z"
    />
  </svg>
</template>
