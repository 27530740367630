import store from '@/store/index';
import config from "../../../config";

function createPreviewPaths(name) {
  return {
    path: 'preview',
    name: `${name}-preview`,
    component: () => import('~layout/ExerciseLayout'),
    meta: {
      title: 'Задание',
      permissions: ['TEACHER'],
      bodyClass: 'coloredBackground',
    },
    children: [
      {
        path: ':unitName',
        name: `${name}-intro-preview`,
        component: () => import('~pages/Exercise'),

        props: route => ({
          testId: null,
          preview: true,
          unitName: route.params.unitName,
          unitKey: 1,
          pageKey: 1,
        }),
      },
      {
        path: ':unitName/:unitKey',
        name: `${name}-unit-preview`,
        component: () => import('~pages/Exercise'),
        props: route => ({
          testId: null,
          preview: true,
          unitName: route.params.unitName,
          unitKey: Number(route.params.unitKey),
          pageKey: 1,
        }),
      },
      {
        path: ':unitName/:unitKey/:pageKey',
        name: `${name}-question-preview`,
        component: () => import('~pages/Exercise'),
        props: route => ({
          testId: null,
          preview: true,
          unitName: route.params.unitName,
          unitKey: Number(route.params.unitKey),
          pageKey: Number(route.params.pageKey),
        }),
      },
    ],
  };
}

function checkAccess(to, role) {
  if (to.meta.permissions) {
    return to.meta.permissions.includes(role);
  }

  return true;
}

function navigate(to, next) {
  const { role } = store.state.user;
  if ((checkAccess(to, role) && !to.meta.noAuth) || to.fullPath === '/404') next();
  else {
    switch (role) {
      case 'TEACHER':
        next('/exercises-catalog');
        break;
      case 'STUDENT':
        next('/student-tests');
        break;
      default:
        document.location.href = `${config.APP_V2_URL}/profile`;
        break;
    }
  }
}

async function checkAuth(to, from, next) {
  const { dispatch, state, getters } = store;
  const isUserLoaded = getters['user/isUserLoaded'];
  if (!isUserLoaded) {
    await dispatch('user/FETCH_USER_INFO');
  }
  const { id } = state.user;
  try {
    if (!id) {
      return to.meta.noAuth ? next() : next('login');
    }
    navigate(to, next);
  } catch (error) {
    return false;
  }

  return true;
}

export {
  checkAuth,
  createPreviewPaths,
};
