import api2 from '@/services/api2';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async sendInviteTeacher(empty, email) {
      return api2.post('/mail/teacher-invite', { email });
    },
  },
};
