export default {
  navs: {
    Профиль: 'Профиль',
    'Школы и учителя': 'Школы и учителя',
    'Каталог заданий': 'Тапсырмалар каталогы',
    'Мои классы': 'Менің сыныбым',
    'Мои работы': 'Менің жұмысым',
    'Список пользователей': 'Пайдаланушылардың тізімі',
    'Создать пользователя': 'Пайдаланушыны жасау',
    Учителя: 'Учителя',
    'Классы и ученики': 'Классы и ученики',
    Статистика: 'Статистика',
    Помощь: 'Помощь',
  },
  themes: {
    creative: 'Креативті ойлау',
    mathlit: 'Математикалық сауаттылық',
  },
  student: '{n} оқушы',

  Вопрос: 'Сұрақ',

  // Страница регистраии и входа

  'Все права защищены.': 'Барлық құқықтар қорғалған.',
  '© 2023, ТОО «Amansultan» Все права защищены.': '© 2021, «AmanSultan ЖШС» Барлық құқықтар қорғалған.',
  'Тренажеры по развитию функциональной грамотности.': 'Функционалдық сауаттылықты дамыту тренажері.',
  'Международная программа по оценке образовательных достижений учащихся.': 'Оқушылардың білім жетістіктерін бағалау жөніндегі халықаралық бағдарлама.',
  'С паролем': 'Құпиясөз арқылы',
  'Задать пароль': 'Мен құпиясөз қойдым',
  'Я задал пароль': 'Мен құпиясөз қойдым',
  'По коду': 'Код арқылы',
  'Введите код': 'Кодты енгізіңіз',
  'Выдан учителем': 'Мұғаліммен берілді',
  'Через Almaty Sifrlyq Mektebi': 'Almaty Sifrlyq Mektebi арқылы',
  'Через ERG Digital Mektebi': 'ERG Digital Mektebi арқылы',
  'Через AmanSultan': 'AmanSultan арқылы',
  'Войти через ERG Digital Mektebi': 'Кіру ERG Digital Mektebi арқылы',
  'Войти через Almaty Sifrlyq Mektebi': 'Кіру Almaty Sifrlyq Mektebi арқылы',
  'Войти через AmanSultan': 'Кіру AmanSultan арқылы',
  Авторизация: 'Авторизациялау',
  Почта: 'Пошта',
  'Введите свой Email': 'Email енгізіңіз',
  Пароль: 'Құпиясөз',
  'Введите свой пароль': 'Құпиясөзді енгізіңіз',
  'Запомнить меня': 'Мені есте сақтау',
  Войти: 'Кіру',
  'Поле обязательно для заполнения!': 'Жол міндетті түрде толтырылуы керек!',
  userAssign: {
    text: '"Кіру" батырмасын басу арқылы мен {0} қабылдаймын',
    link: 'пайдаланушының келісімін ',
  },

  // Страница классов
  'Мои классы': 'Менің сыныбым',
  'Просмотр классов': 'Сыныпты қарау',
  'Добавить класс': 'Сыныпты қосу',
  'Назовите ваш класс': 'Сыныбыңызға ат қойыңыз',
  'Введите номер и название класса': 'Сыныптың нөмірі мен атын енгізіңіз',
  'Создать класс': 'Сыныпты құру',
  'ФИО ученика': 'Оқушының ТАӘ',
  Код: 'Код',
  'Код для входа': 'Кіру коды',
  'Распечатать все коды': 'Барлық кодтарды басып шығару',
  'Вернуться к списку': 'Тізімге оралу',
  'Вернуться к классам': 'Вернуться к классам',
  'Добавить ученика': 'Оқушы қосу',
  'Удалить класс': 'Сыныпты жою',
  'E-mail': 'E-mail',
  'Загрузить список': 'Жүктеп алу тізімі',
  'Редактирование ученика': 'Оқушыны өңдеу',
  'Добавление ученика': 'Оқушы қосу',
  'Вы уверены, что хотите удалить': 'Жойғыңыз келетініне сенімдісіз бе',
  'Добавьте учеников': 'Оқушыларды қосыңыз',
  'Вернуться в каталог': 'Каталогқа оралу',
  'Добавление класса': 'Сынып қосу',
  'Скопируйте и вставьте список учеников или заполните самостоятельно': 'Оқушылар тізімін көшіріп қойыңыз немесе өзіңіз толтырыңыз',
  Добавить: 'Қосу',
  'Добавление учеников': 'Оқушыларды қосу',
  'Показывать актуальный список': 'Жаңартылған тізімді көрсетіңіз',

  // Страница учителей
  'Добавление учителя': 'Мұғалімді қосу',
  Предмет: 'Предмет',
  'Редактирование учителя': 'Мұғалімнің редакциясы',
  'Отправить приглашение': 'Шақыру жіберу',
  'Сохранить изменения': 'Өзгерістерді сақтау',
  'Редактирование пароля': 'Құпия сөзді өңдеу',
  Сбросить: 'Қалпына келтіру',
  Школа: 'Мектеп',
  'Вы уверены, что хотите удалить учителя': 'Мұғалімді жойғыңыз келетініне сенімдісіз бе',

  // Страница школ
  'Редактирование школы и администратора': 'Мектеп және әкімші редакциясы',
  'Название школы': 'Мектеп аты',
  'Вы уверены, что хотите удалить школу': 'Мектепті жойғыңыз келетініне сенімдісіз бе',
  'Добавление школы и администратора': 'Мектеп пен әкімшіні қосу',
  'Город / населенный пункт': 'Қала / елді мекен',
  'Администратор школы': 'Мектеп әкімшісі',
  'Добавление нового администратора': 'Жаңа әкімші қосу',
  Телефон: 'Телефон',

  // Страница ответственного
  'Повторите пароль': 'Құпия сөзді қайталаңыз',
  'Вы уверены, что хотите удалить ответственного': 'Иесін жойғыңыз келетініне сенімдісіз бе',

  // Страница работ учителя
  'Мои работы': 'Менің жұмысым',
  'Вернуться к моим работам': 'Менің жұмыстарыма қайта оралу',
  'Название модуля': 'Модуль атауы',
  Класс: 'Сынып',
  'Дата выдачи': 'Берілген күні',
  Ссылка: 'Сілтеме',
  'Выполнение задания': 'Тапсырманы орындау',
  Статус: 'Мәртебесі',
  Выдано: 'Берілді',
  Выполняется: 'Орындалады',
  'Требует проверки': 'Тексеруді талап етеді',
  'Проверка завершена': 'Тексеру аяқталды',
  ФИО: 'Аты-жөні',
  'Задание назначено. Перейти к просмотру назначенных заданий?': 'Тапсырма беріледі. Тағайындалған тапсырмаларды қарауға өту керек пе?',
  Перейти: 'Бару',
  'Не начато': 'Басталмаған',
  Сегодня: 'Бүгін {in}',
  Вчера: 'Кеше {in}',

  // Каталог уроков
  Каталог: 'Каталог',
  'Математическая грамотность': 'Математикалық сауаттылық',
  'Креативное мышление': 'Креативті ойлау',
  'Название темы': 'Тақырыптың атауы',
  'Назначить задание': 'Тапсырманы тағайындау',
  'Название задания': 'Тапсырманың атауы',
  'Ограничение по времени в минутах': 'Уақыт бойынша шектеу минутпен',
  Классы: 'Сыныптар',
  Группы: 'Топтар',
  Ученики: 'Оқушылар',
  Назначить: 'Тағайындау',
  'Предварительный просмотр': 'Алдын ала қарау',
  'Время должно быть больше 0': 'Уақыт 0-ден көп болуы тиіс',
  'Необходимо выбрать хотя бы один класс': 'Кем дегенде бір сыныпты таңдау қажет',
  'Критерии оценки': 'Бағалау критерийлері',

  // Профиль
  'Профиль учителя': 'Мұғалімнің профилі',
  Фамилия: 'Тегі',
  Имя: 'Аты',
  Отчество: 'Әкесінің аты',
  Страна: 'Ел',
  Регион: 'Өңір',
  Район: 'Район',
  Город: 'Город',
  'Населённый пункт': 'Елді мекен',
  'Образовательная организация': 'Білім беру ұйымы',
  'Номер телефона': 'Телефон нөмірі',
  'Личные данные': 'Жеке мәліметтер',
  'Учебные заведения': 'Оқу орындары',
  Далее: 'Әрі қарай',
  Буква: 'Хат',
  'Выберите школу из списка или начните вводить номер или название школы': 'Тізімнен мектепті таңдаңыз немесе мектеп нөмірін не атауын теруді бастаңыз',
  'Информация профиля': 'Профиль ақпараты',
  Логин: 'Логин',
  Идентификатор: 'Идентификатор',
  Роль: 'Рөлі',
  'Дата регистрации': 'Тіркеу күні',
  'Редактировать данные': 'Мәліметтерді өңдеу',
  Сохранить: 'Сақтау',

  'Название работы': 'Жұмыстың атауы',
  Начато: 'Басталды',
  Время: 'Уақыты',
  Прогресс: 'Прогресс',
  Начать: 'Бастау',
  Продолжить: 'Жалғастыру',
  'На проверке': 'Тексеруде',

  // Модалка помощи урока
  Помощь: 'Көмек',
  'Здесь вы можете найти информацию о тесте и о том, как отвечать на вопросы.': 'Мұнда сіз тест және сұрақтарға қалай жауап беру туралы ақпаратты таба аласыз.',
  'Нажмите на ссылку или используйте прокрутку, чтобы найти необходимую вам информацию.': 'Сілтемені басыңыз немесе қажетті ақпаратты табу үшін айналдыруды пайдаланыңыз.',
  'Перемещение по тесту и оценка продвижения по тесту': 'Тест бойынша жылжу және тест бойынша алға жылжуды бағалау',
  'Нажмите на стрелку ДАЛЕЕ, чтобы перейти к следующему вопросу или блоку вопросов.': 'Келесі сұраққа немесе сұрақтар блогына өту үшін ӘРІ ҚАРАЙ көрсеткісін басыңыз.',
  'Нажмите на стрелку НАЗАД, чтобы вернуться к предыдущему вопросу или блоку вопросов.': 'Алдыңғы сұраққа немесе сұрақтар блогына оралу үшін КЕРІ көрсеткісін басыңыз.',
  'Если переход назад невозможен, стрелка будет окрашена в серый цвет.': 'Егер кері өту мүмкін болмаса, көрсеткі сұр түспен боялатын болады.',
  'Каждый квадрат индикатора продвижения соответствует одному вопросу.': 'Жылжыту индикаторының әрбір шаршысы бір сұраққа сәйкес келеді.',
  'Задание, над которым вы работаете в настоящее время, окрашено в белый цвет.': 'Қазіргі уақытта сіз жұмыс істеп жатқан тапсырма ақ түспен белгіленген.',
  'Индикатор показывает оставшееся время на выполнение задания.': 'Индикатор тапсырманы орындау үшін қалған уақытты көрсетеді.',
  'Как отвечать на вопросы': 'Сұрақтарға қалай жауап беру керек',
  'Отметьте нужный вариант ответа:': 'Қажетті жауап нұсқасын белгілеңіз:',
  'такие вопросы имеют только один правильный ответ.': ' мұндай сұрақтардың бір ғана дұрыс жауабы бар.',
  'Отметьте нужный вариант ответа. При этом будет закрашен центр отмеченного кружка.': '  Қажетті жауапты белгілеңіз. Бұл ретте, белгіленген дөңгелектің ортасы боялады.',
  'Чтобы изменить свой ответ, отметьте другой вариант ответа.': 'Жауабыңызды өзгерту үшін басқа жауап нұсқасын белгілеңіз.',
  'Отметьте один или несколько вариантов ответа:': 'Жауаптың бір немесе бірнеше нұсқасын белгілеңіз:',
  'такие вопросы могут иметь более одного правильного ответа.': ' мұндай сұрақтарға бірнеше дұрыс жауап болуы мүмкін.',
  'Отметьте один или несколько вариантов ответа. В каждой выбранной ячейке появится галочка.': 'Жауаптың бір немесе бірнеше нұсқасын белгілеңіз. Әрбір таңдалған ұяшықта белгі пайда болады.',
  'Чтобы изменить свой ответ, нажмите на выбранную вами ячейку ещё раз. Галочка исчезнет.': 'Жауабыңызды өзгерту үшін таңдалған ұяшықты тағы бір рет басыңыз. Белгі жоғалады.',
  'Отметьте график или изображение:': 'Графикті немесе суретті белгілеңіз:',
  'Нажмите на график или изображение, и выбранный вами объект подсветится.': 'Графикті немесе суретті басыңыз, сонда сіз таңдаған нысан жарықтандырылады.',
  'Чтобы изменить свой ответ, нажмите на график или изображение ещё раз. Подсветка исчезнет.': 'Жауабыңызды өзгерту үшін графикті немесе суретті тағы бір рет басыңыз. Артқы жарықтандыру жоғалады.',
  'Запишите ответ:': 'Жауабыңызды жазыңыз:',
  'Щёлкните мышью внутри текстового поля и запишите свой ответ при помощи клавиатуры.': 'Мәтін жолағының ішіндегісіне тінтуірмен шертіңіз және пернетақтаның көмегімен жауабыңызды жазыңыз.',
  'Используйте клавишу Backspace, чтобы стереть написанное.': 'Жазылғанды өшіру үшін Backspace пернесін пайдаланыңыз.',
  'Запишите цифрами ответ на вопрос:': 'Сұрақтың жауабын сандармен жазыңыз:',
  'вы НЕ МОЖЕТЕ использовать клавиши с буквами, чтобы ответить на вопросы такого типа. Используйте клавиши с цифрами и любые из следующих пяти клавиш:': 'сіз осы түрдегі сұрақтарға жауап беру үшін әріптер пернелерін ПАЙДАЛАНА АЛМАЙСЫЗ. Сандары бар пернелерді және келесі бес перненің кез келгенін пайдаланыңыз:',
  'Запятая [ , ]': '  Үтір [ , ]',
  'Точка [ . ]': '  Нүкте [ . ]',
  'Слэш (косая черта) [ / ] для записи обыкновенных дробей': '  Слэш (қисық сызық) [ / ] жай бөлшектерді жазу үшін',
  'Дефис [ - ] для записи отрицательных чисел': '  Сызықша [ - ] теріс сандарды жазу үшін',
  ПРОБЕЛ: 'АРАЛЫҚ',
  'Используйте выпадающее меню:': 'Қалқымалы мәзірді қолданыңыз:',
  'Нажмите на стрелку.': '  Көрсеткіні басыңыз.',
  'Выберите вариант ответа.': '  Жауап  нұсқасын таңдаңыз.',
  'Вариант ответа, который вы выбрали, появится в верхней строке меню.': '  Сіз таңдаған жауап жоғарғы мәзір жолағында пайда болады.',
  'Чтобы изменить свой ответ, повторите описанные выше шаги, выбрав при этом другой вариант ответа.': '  Жауабыңызды өзгерту үшін басқа жауапты таңда отырып, жоғарыда атап өткен қадамдарды қайталаңыз.',
  'Используйте метод «Перетащить и оставить»:': '"Жылжыту және қалдыру" әдісін қолданыңыз:',
  'Нажмите и удерживайте кнопку мыши над объектом, который вы хотите перетащить.': ' Жылжытқыңыз келетін нысанның үстінен тінтуір батырмасын басып тұрыңыз.',
  'Удерживая кнопку мыши нажатой, перетащите объект в нужное место.': '  Тінтуір батырмасын басып тұрып, нысанды қажетті жерге жылжытыңыз.',
  'Отпустите кнопку мыши. Объект окажется в выбранном вами месте.': '  Тінтуір батырмасын босатыңыз. Нысан сіз таңдаған жерде болады.',
  'Чтобы изменить свой ответ, перетащите объект в его первоначальное положение.': 'Жауабыңызды өзгерту үшін нысанды бастапқы орнына жылжытыңыз.',
  'Перемещение по страницам': 'Беттер бойынша жылжу',
  'Перемещение по страницам:': 'Беттер бойынша жылжу:',
  'некоторые блоки заданий содержат материал для чтения, который расположен более чем на одной странице.': ' кейбір жұмыс блоктарында бірнеше бетте орналасқан оқу материалы бар.',
  'Для перемещения по страницам нажмите на номер страницы.': 'Беттер бойынша жылжу үшін бет нөмірін басыңыз.',
  'Для каждого вопроса страница 1 всегда отображается первой.': '  Әр сұрақ үшін 1-бет әрқашан бірінші болып көрсетіледі.',
  'Вы можете переходить вперёд и назад по страницам в любой момент при выполнении данного блока заданий.': 'Осы тапсырмалар блогын орындау кезінде кез-келген уақытта парақ бойынша алға мен артқа өтуге болады.',

  // Дропзона для скачивания файлов с учителями, учениками, школами.
  'Перетащите в эту область файл формата xls': 'Пішім файлын осы аймаққа апарыңыз xls',
  'Скачать шаблон xls': 'Үлгіні жүктеп алу xls',
  Загрузить: 'Жүктеп алу',

  // Критерии оценки в заданиях
  'Содержательная область оценки': 'Бағалаудың мазмұндық бөлігі',
  'Компетентностная область оценки': 'Бағалаудың құзыреттілік бөлігі',
  'СИСТЕМА ОЦЕНИВАНИЯ': 'БАҒАЛАУ ЖҮЙЕСІ',
  'ПРИМЕРЫ КАТЕГОРИЙ': 'САНАТ МЫСАЛДАРЫ',
  Обычные: 'Қарапайым',
  Необычные: 'Ерекше',
  'Обычные тексты': 'Қарапайым мәтіндер',
  'Необычные тексты': 'Ерекше мәтіндер',
  'Необычный значки': 'Ерекше таңбашалар',
  'Необычные значки': 'Ерекше таңбашалар',
  'Обычные значки': 'Қарапайым таңбашалар',
  'Подтвердите действие': 'Әрекетті растау',
  Да: 'Иә',
  Нет: 'Жоқ',
  класс: 'сынып',
  'Выполнили работу': 'Тапсырманы орындады',
  'Ученик удален': 'Оқушы жойылды',
  'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать классы.': 'Сіздің профиліңізде онымен байланыстырылған мектеп жоқ. Сіз сыныптарды көре немесе өңдей алмайсыз.',
  'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать учителей.': 'Профильмен байланыстырылған мектеп жоқ. Мұғалімдерді қарау немесе өңдеу мүмкін емес.',
  'Администраторы школы': 'Мектеп әкімшілері',
  'Редактирование школы': 'Мектеп редакциясы',
  'Восстановление пароля': 'Құпия сөзді қалпына келтіру',
  'Изменить пароль': 'Құпия сөзді өзгерту',
  Отправить: 'Жіберу',
  'Для вашей роли отключена синхронизация': 'Рөліңіз үшін синхрондау өшірілген',
  'Синхронизация отключена: ваш профиль не привязан к аккаунту ОШ или аккаунта не существует.': 'Синхрондау өшірілген: профиліңіз OH тіркелгісімен байланыспаған немесе тіркелгі жоқ.',
  'Синхронизация отключена: у вашего профиля осутствует логин.': 'Синхрондау өшірілген: профиліңізде логин жоқ.',
  'Скачать статистику школы': 'Мектеп статистикасын жүктеп алыңыз',
  'Скачать статистику учителей': 'Мұғалімнің статистикасын жүктеп алыңыз',
  'Скачать статистику': 'Статистиканы жүктеп алыңыз',
  'Скачать статистику классов': 'Сынып статистикасын жүктеп алыңыз',
  'Добавить школу': 'Мектепті қосыңыз',
  'Скачать статистику по району': 'Аймақ бойынша статистиканы жүктеп алыңыз',
  'Скачать статистику по региону': 'Аймақ бойынша статистиканы жүктеп алыңыз',
};
