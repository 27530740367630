<template>
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.91632 6.49502C4.75571 6.47622 4.60625 6.40347 4.49238 6.2887L0.208348 2.0061C0.142041 1.93947 0.0895135 1.86043 0.0537653 1.7735C0.0180172 1.68657 -0.000251501 1.59344 2.61502e-06 1.49945C0.000256731 1.40546 0.0190286 1.31244 0.0552463 1.2257C0.091464 1.13896 0.144418 1.06021 0.211085 0.993936C0.277751 0.927658 0.356825 0.875148 0.44379 0.839422C0.530755 0.803695 0.623907 0.785452 0.717929 0.785717C0.81195 0.785982 0.904999 0.804753 0.991761 0.840968C1.07852 0.877184 1.1573 0.930126 1.22359 0.996778L5 4.77194L8.77641 0.996778C8.8427 0.930126 8.92148 0.877184 9.00824 0.840968C9.095 0.804753 9.18805 0.785982 9.28207 0.785717C9.37609 0.785452 9.46925 0.803695 9.55621 0.839422C9.64318 0.875148 9.72225 0.927658 9.78892 0.993936C9.85558 1.06021 9.90854 1.13896 9.94475 1.2257C9.98097 1.31244 9.99974 1.40546 10 1.49945C10.0003 1.59344 9.98198 1.68657 9.94624 1.7735C9.91049 1.86043 9.85796 1.93947 9.79165 2.0061L5.50762 6.2887C5.43122 6.36574 5.33826 6.42438 5.2358 6.46013C5.13334 6.49588 5.02408 6.50781 4.91632 6.49502Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'AngleDown',
};
</script>
