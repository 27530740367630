<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.9484 0.961634V0.884724L19.8973 0.807815V0.756483H19.8975C19.843 0.675641 19.7736 0.606062 19.6934 0.551332H19.6423L19.5658 0.5H13.3413C13.0679 0.5 12.8152 0.646662 12.6786 0.88472C12.5417 1.1226 12.5417 1.41595 12.6786 1.65399C12.8152 1.89187 13.0679 2.03854 13.3413 2.03854H17.3719L5.45854 13.9359C5.28113 14.0697 5.17009 14.2743 5.15425 14.4968C5.1386 14.7192 5.21974 14.9375 5.37651 15.0953C5.53346 15.2528 5.75073 15.3344 5.9719 15.3187C6.19327 15.3027 6.39684 15.1911 6.52994 15.0128L18.4688 3.0385V7.08977C18.4688 7.36449 18.6146 7.61847 18.8514 7.75584C19.0882 7.89338 19.3799 7.89338 19.6168 7.75584C19.8534 7.61847 19.9993 7.3645 19.9993 7.08977V1.19235C19.9961 1.1403 19.9876 1.08879 19.9739 1.03853L19.9484 0.961634Z" />
    <path d="M2.80605 20.5H13.2143C13.9587 20.5 14.6724 20.2029 15.1986 19.6738C15.725 19.1449 16.0205 18.4275 16.0205 17.6796V12.7821C16.0205 12.5072 15.8746 12.2533 15.638 12.1159C15.4011 11.9785 15.1093 11.9785 14.8726 12.1159C14.6357 12.2533 14.4898 12.5072 14.4898 12.7821V17.6796C14.4898 18.0196 14.3555 18.3456 14.1163 18.586C13.8772 18.8264 13.5526 18.9616 13.2143 18.9616H2.80605C2.46778 18.9616 2.14338 18.8264 1.90422 18.586C1.66506 18.3456 1.53053 18.0196 1.53053 17.6796V7.21805C1.53053 6.87804 1.66506 6.5518 1.90422 6.31142C2.14338 6.07103 2.46777 5.936 2.80605 5.936H7.67854C7.95205 5.936 8.20474 5.78933 8.3414 5.55128C8.47807 5.3134 8.47807 5.02005 8.3414 4.782C8.20474 4.54412 7.95206 4.39746 7.67854 4.39746H2.80605C2.06188 4.39746 1.34813 4.69454 0.821936 5.2236C0.295556 5.75249 0 6.46989 0 7.21806V17.6796C0 18.4275 0.29557 19.1449 0.821936 19.6738C1.34813 20.2029 2.06188 20.5 2.80605 20.5L2.80605 20.5Z" />
  </svg>
</template>
