<template>
  <div class="base2-layout">
    <div class="base2-layout__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Base2Layout",
};
</script>

<style lang="scss">
.base2-layout {
  display: flex;
  flex-direction: column;
  &__content {
    flex: 1;
  }
}
</style>
