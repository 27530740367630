<template>
  <div class="base-input__wrapper">
    <div
      class="base-input__inner"
      :class="posLabelClass"
    >
      <label
        v-if="compLabel"
        class="base-input__label"
      >{{ compLabel }}</label>
      <input
        ref="element"
        v-model="value"
        class="base-input"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :type="type"
      >
    </div>
    <div
      v-if="compErrors"
      class="base-input__errors"
    >
      <div
        v-for="error in compErrors"
        :key="error"
        class="base-input__error"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch, toRef,
} from 'vue';
import Inputmask from 'inputmask';

export default {
  name: 'BaseInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    keyName: {
      type: String,
      default: null,
    },
    label: {
      type: [Object, String],
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:modelValue'],
  setup(props, { emit }) {
    const element = ref(null);
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
        if (props.keyName) {
          emit('input', val, this.keyName);
        } else {
          emit('input', val);
        }
      },
    });
    const compLabel = computed(() => props.label.title || props.label);

    const posLabelClass = computed(() => {
      let className = 'base-input__inner--';
      switch (props.label.position) {
        case 'left':
          className += 'left';
          break;
        default:
          className = null;
          break;
      }
      return className;
    });

    const compErrors = computed(() => {
      const { errors } = props;
      const arr = [];

      if (errors && errors.length) {
        errors.forEach(item => {
          if (item) {
            arr.push(item);
          }
        });
      }

      return arr.length ? arr : null;
    });

    function setMask(elementInp) {
      Inputmask({
        mask: props.mask,
        clearMaskOnLostFocus: false,
        placeholder: '.',
      }).mask(elementInp);
    }
    function removeMask(elementInp) {
      Inputmask.remove(elementInp);
    }

    onMounted(() => {
      if (props.mask) {
        setMask(element.value);
      }
    });

    if (props.mask) {
      watch(toRef(props, 'mask'), () => {
        removeMask(element.value);
        setMask(element.value);
      });
    }

    return {
      element,
      compErrors,
      compLabel,
      value,
      posLabelClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  height: 42px;
  background-color: white;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 10px 15px;
  width: 100%;
  transition: border 0.3s;
  font-family: inherit;
  font-size: 15px;
  margin-bottom: 24px;
  &__wrapper {
    width: 100%;
  }
  &__errors {
    margin-bottom: 15px;
  }
  &__error {
    font-size: 15px;
    margin-bottom: 10px;
    color: red;
  }

  &__inner {
    &--left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .base-input {
        margin-bottom: 0;
        &__label {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  &:focus {
    outline-color: #8654ef;
    outline-width: 1px;
  }

  &::placeholder {
    color: #66626e;
    font-weight: 100;
  }

  &.error {
    border-color: $pink !important;

    &:focus {
      outline-color: $pink !important;
    }
  }
  &__label {
    display: block;
    font-size: 16px;
    line-height: 21px;
    color: #001842;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

#pisa .base-input {
  &:focus {
    outline-color: $pisa-purple;
  }
}

#erg .base-input {
  &:focus {
    outline-color: $erg-blue;
  }
}

#almaty .base-input {
  &:focus {
    outline-color: $almaty-green;
  }
}

#sultan .base-input {
  &:focus {
    outline-color: $sultan-blue;
  }
}
</style>
