<template>
  <div
    v-if="filters"
    class="base-filter-category"
  >
    <label
      v-for="(filter, filterIdx) in filters"
      :key="filterIdx"
      class="base-filter-category__item"
      :class="{ selected: isSelected(filter._id) }"
    >{{ $t(`themes.${filter._id}`) }}
      <input
        v-model="selectedFilters"
        :value="filter._id"
        type="checkbox"
      >
    </label>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
  name: 'BaseFilterPanelCategory',
  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: [Array, Object],
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const isSelected = data => {
      const boolean = Boolean(modelValue.value.find(item => item === data));

      return boolean;
    };
    const selectedFilters = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    return {
      selectedFilters,
      isSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-filter-category {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &__item {
    display: block;
    background: #efeef1;
    border-radius: 20px;
    color: #001842;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 16px;
    user-select: none;
    input {
      display: none;
    }

    &.selected {
      background: linear-gradient(180deg, #8654ef 0%, #7343d7 100%);
      color: #ffffff;
    }
  }

  @media screen and (max-width: 575px) {
    &__item {
      font-size: 14px;
    }
  }
}

#almaty {
  .base-filter-category__item {
    &.selected {
      background: linear-gradient(#24cb9f, #11ab82);
    }
  }
}

#erg {
  .base-filter-category__item {
    &.selected {
      background: linear-gradient(#032af4, #0719a6);
    }
  }
}

#sultan {
  .base-filter-category__item {
    &.selected {
      background: linear-gradient(#3485ff, rgb(0, 97, 241));
    }
  }
}
</style>
